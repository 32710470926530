<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form v-model="valid">
        <v-container class="pa-0">
          <v-row
            v-if="!$apollo.queries.getWebServiceUser.loading"
            class="mb-2"
          >
            <v-col class="col-12 col-md-6">
              <ekitabu-masked-phone-input
                id="phone-input"
                :value="accountPhone"
                class="mt-2"
                :label="$t('checkout.form.phoneNumber')"
                rules="required|max:9|min:9"
                placeholder="### ### ###"
                type="tel"
                required
                :dropdown-disabled="true"
                @formattedPhoneChanged="setFormattedPhone"
              />
              <admin-order
                v-if="isAdminOrder"
                class="mt-3"
                :show-phone-input="false"
                @updateAdminOrderDetails="setAdminOrderDetails"
              />
              <delivery-options
                v-if="isPrintOrder"
                @updateShippingInfo="setShippingInfo"
                @updateSchoolInfo="setSchoolInfo"
                @updateIsPickupOrder="setIsPickupOrder"
                @updateIsValid="setDeliveryOptionsValid"
              />
              <v-checkbox
                v-if="formattedPhone != accountPhone && !invalid"
                v-model="saveAccountInfo"
                :label="$t('checkout.saveAccountInfo')"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <place-order-button
            :should-save-account-info="saveAccountInfo"
            :invalid="invalid || !deliveryOptionsValid"
            :prefixed-phone-number="formattedPhone"
            :shipping-info="shippingInfo"
            :school-info="schoolInfo"
            :is-pickup-order="isPickupOrder"
            :is-admin-order="isAdminOrder"
            :admin-order-details="adminOrderDetails"
          />
        </v-container>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import PlaceOrderButton from "@/views/checkout/MpesaPlaceOrderButton";
import { required, max, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import i18n from "@/i18n";
import EkitabuMaskedPhoneInput from "@/components/EkitabuMaskedPhoneInput";
import DeliveryOptions from "@/views/checkout/print/DeliveryOptions";
import gql from "graphql-tag";
import AdminOrder from "@/views/checkout/AdminOrder";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: i18n.t("errors.required", { field: "{_field_}" }),
});

extend("max", {
  ...max,
  message: i18n.t("errors.maxLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("min", {
  ...min,
  message: i18n.t("errors.minLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

export default {
  name: "MpesaScreen",
  components: {
    PlaceOrderButton,
    ValidationObserver,
    EkitabuMaskedPhoneInput,
    DeliveryOptions,
    AdminOrder,
  },
  props: {
    isPrintOrder: {
      type: Boolean,
      default: false,
    },
    isAdminOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accountPhone: "",
      valid: false,
      saveAccountInfo: false,
      formattedPhone: "",
      shippingInfo: {
        altPhoneNumber: null,
        addressDetails: null,
        placeId: null,
        eventId: null,
      },
      schoolInfo: {
        schoolId: null,
        studentName: null,
        studentClass: null,
      },
      isPickupOrder: false,
      deliveryOptionsValid: this.isPrintOrder ? false : true,
      adminOrderDetails: {
        forEmail: null,
        forName: null,
        phoneNumber: null,
        event: null,
      },
    };
  },
  apollo: {
    getWebServiceUser: {
      query: gql`
        query getUserInfo {
          getWebServiceUser {
            id
            email
            familyName
            givenName
            phoneNumber
            emailVerified
            alternativePhoneNumber
          }
        }
      `,
      result({ data }) {
        this.accountPhone = data.getWebServiceUser?.phoneNumber;
      },
    },
  },
  methods: {
    setFormattedPhone(value) {
      this.formattedPhone = value;
    },
    setShippingInfo(shippingInfo) {
      this.shippingInfo = shippingInfo;
    },
    setSchoolInfo(schoolInfo) {
      this.schoolInfo = schoolInfo;
    },
    setIsPickupOrder(isPickupOrder) {
      this.isPickupOrder = isPickupOrder;
    },
    setDeliveryOptionsValid(isValid) {
      this.deliveryOptionsValid = isValid;
    },
    setAdminOrderDetails(adminOrderDetails) {
      this.adminOrderDetails = adminOrderDetails;
    },
  },
};
</script>
