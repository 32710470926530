var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ApolloMutation',{attrs:{"mutation":(gql) => _vm.removeCouponMutation,"variables":{
    id: _vm.orderId,
  },"refetch-queries":() => [{
    query: _vm.getOrderQuery,
    variables: { 
      orderId: _vm.orderId,
    },
  }]},on:{"done":_vm.onDone},scopedSlots:_vm._u([{key:"default",fn:function({mutate, loading}){return [_c('v-btn',{staticClass:"pa-0",attrs:{"loading":loading,"disabled":loading,"text":"","color":"primary","tooltop":"Remove Coupon"},on:{"click":mutate}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }