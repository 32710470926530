<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form>
        <h4 class="mb-2 mt-6">
          {{ $t('checkout.creditCardInfo') }}
        </h4>
        <p>{{ $t('checkout.creditCardHelpText') }}</p>
        <v-row>
          <v-col class="col-12 col-sm-9 col-md-6 pb-0">
            <ekitabu-text-input
              id="fullname-input"
              v-model="paymentDetails.cardHolderName"
              class="fill-width"
              :label="$t('checkout.form.cardholderName')"
              rules="required|max:50|min:3"
              :placeholder="$t('checkout.form.cardholderName')"
              type="name"
              required
              autocomplete="cc-name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-sm-9 col-md-6 pb-0">
            <ekitabu-text-input
              id="cardnumber-input"
              v-model="cardNumber"
              v-mask="'#### #### #### ####'"
              class="fill-width"
              :label="$t('checkout.form.cardNumber')"
              rules="required|credit_card"
              placeholder="#### #### #### ####"
              type="credit-card"
              required
              autocomplete="cc-number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-6 col-sm-5 col-md-3">
            <ekitabu-text-input
              id="expiry-date-input"
              v-model="expirationDate"
              type="text"
              name="expirationDate"
              mask="##/####"
              class="fill-width"
              :label="$t('checkout.form.expiryDate')"
              :placeholder="$t('checkout.form.expiryPlaceholder')"
              rules="required|expiry_date"
              required
              autocomplete="cc-exp"
            />
          </v-col>
          <v-col class="col-6 col-sm-4 col-md-3">
            <ekitabu-text-input
              id="cvv-input"
              v-model="paymentDetails.cardCvv"
              v-mask="'####'"
              class="fill-width"
              :label="$t('checkout.form.cvv')"
              rules="required|cvv"
              placeholder="###"
              required
              autocomplete="cc-csc"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ekitabu-masked-phone-input
              v-if="isPrintOrder && !isAdminOrder"
              id="phone-input"
              :value="phoneNumber"
              class="mt-2"
              :label="$t('checkout.form.phoneNumber')"
              rules="required|max:9|min:9"
              placeholder="### ### ###"
              type="tel"
              required
              @formattedPhoneChanged="setFormattedPhone"
            />
            <admin-order
              v-if="isAdminOrder"
              @updateAdminOrderDetails="setAdminOrderDetails"
            />
            <delivery-options
              v-if="isPrintOrder"
              @updateShippingInfo="setShippingInfo"
              @updateSchoolInfo="setSchoolInfo"
              @updateIsPickupOrder="setIsPickupOrder"
              @updateIsValid="setDeliveryOptionsValid"
            />
          </v-col>
        </v-row>
        <credit-card-place-order-button
          class="mt-6"
          :payment-info="paymentDetails"
          :should-save-account-info="saveAccountInfo"
          :invalid="invalid || !deliveryOptionsValid"
          :shipping-info="shippingInfo"
          :school-info="schoolInfo"
          :is-pickup-order="isPickupOrder"
          :is-admin-order="isAdminOrder"
          :admin-order-details="adminOrderDetails"
          :phone-number="phoneNumber"
        />
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import EkitabuTextInput from "@/components/EkitabuTextInput";
import CreditCardPlaceOrderButton from "@/views/checkout/CreditCardPlaceOrderButton";
import { required, max, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import i18n from "@/i18n";
import DeliveryOptions from "@/views/checkout/print/DeliveryOptions";
import AdminOrder from "@/views/checkout/AdminOrder";
import EkitabuMaskedPhoneInput from "@/components/EkitabuMaskedPhoneInput";
import gql from "graphql-tag";

setInteractionMode("aggressive");

extend("required", {
  ...required,
  message: i18n.t("errors.required", { field: "{_field_}" }),
});

extend("max", {
  ...max,
  message: i18n.t("errors.maxLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("min", {
  ...min,
  message: i18n.t("errors.minLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("credit_card", value => {
  const sanitizedNumber = value.replaceAll(" ", "");
  const regEx = /^[0-9]{16}$/;

  if (sanitizedNumber.match(regEx)) {
    return true;
  }
  else {
    return i18n.t("errors.invalidCreditCard");
  }
});

extend("cvv", value => {
  const regEx = /^[0-9]{3,4}$/;

  if (value.match(regEx)) {
    return true;
  }
  else {
    return i18n.t("errors.invalidCvv");
  }
});

extend("expiry_date", value => {
  const regEx = /^(0[1-9]|1[0-2])\/[0-9]{4}$/
;

  if (value.match(regEx)) {
    return true;
  }
  else {
    return i18n.t("errors.invalidExpiryDate");
  }
});

export default {
  name: "CreditCard",
  components: {
    EkitabuTextInput,
    CreditCardPlaceOrderButton,
    ValidationObserver,
    DeliveryOptions,
    AdminOrder,
    EkitabuMaskedPhoneInput,
  },
  props: {
    isPrintOrder: {
      type: Boolean,
      default: false,
    },
    isAdminOrder: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      cardNumber: null,
      paymentDetails: {
        id: this.$store.state.cart.currentCart,
        cardHolderName: null,
        cardNumber: null,
        cardExpiryMonth: null,
        cardExpiryYear: null,
        cardCvv: null,
      },
      yearCount: 10,
      expirationDate: null,
      shippingInfo: {
        phoneNumber: null,
        altPhoneNumber: null,
        addressDetails: null,
        placeId: null,
      },
      schoolInfo: {
        schoolId: null,
        studentName: null,
        studentClass: null,
      },
      isPickupOrder: false,
      deliveryOptionsValid: this.isPrintOrder ? false : true,
      saveAccountInfo: false,
      adminOrderDetails: {
        forEmail: null,
        forName: null,
        phoneNumber: null,
        event: null,
      },
      phoneNumber: null,
    };
  },
  watch: {
    cardNumber(val) {
      if (val) {
        this.paymentDetails.cardNumber = val.replaceAll(" ", "");
      }
    },
    expirationDate(val) {
      if (val) {
        this.paymentDetails.cardExpiryMonth = val.substring(0, 2);
        this.paymentDetails.cardExpiryYear = val.substring(3, 7);
      }
    },
  },
  apollo: {
    getWebServiceUser: {
      query: gql`
        query getUserInfo {
          getWebServiceUser {
            id
            phoneNumber
            alternativePhoneNumber
          }
        }
      `,
      result({ data }) {
        this.phoneNumber = data.getWebServiceUser?.phoneNumber;
      },
    },
  },
  methods: {
    setFormattedPhone(value) {
      this.phoneNumber = value;
    },
    setShippingInfo(shippingInfo) {
      this.shippingInfo = shippingInfo;
    },
    setSchoolInfo(schoolInfo) {
      this.schoolInfo = schoolInfo;
    },
    setIsPickupOrder(isPickupOrder) {
      this.isPickupOrder = isPickupOrder;
    },
    setDeliveryOptionsValid(isValid) {
      this.deliveryOptionsValid = isValid;
    },
    setAdminOrderDetails(adminOrderDetails) {
      this.adminOrderDetails = adminOrderDetails;
    },
  },
}
</script>