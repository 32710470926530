<template>
  <div class="bookshelf-container">
    <div class="bookshelf-header-container pb-3 mb-3">
      <div class="d-flex align-center mb-3">
        <bookshelf-icon
          :size="$vuetify.breakpoint.xs ? 20 : 32"
          class="icon mr-3"
          color="var(--v-primary-base)"
        />
        <h1 class="bookshelf-header">
          {{ $t('bookshelf.bookshelf') }}
        </h1>
      </div>
      <p class="bookshelf-blurb mb-10">
        {{ $t("bookshelf.blurb") }}
      </p>
    </div>
    <div>
      <ApolloQuery
        :query="(gql) => bookShelfQuery"
        :update="(data) => data.books.collection"
      >
        <template #default="{ result: { error, data }, isLoading, query }">
          <book-display
            :data="data"
            :is-loading="!!isLoading"
            :error="error"
            :query="query"
            :loading-items="loadingItemsCount"
            :pagination-enabled="false"
            :show-owned="false"
          />
          <empty-book-list v-if="!isLoading && (!data || data.length === 0)" />
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>

<script>
  import BookshelfIcon from "@/components/icons/BookshelfIcon";
  import EmptyBookList from "@/components/EmptyBookList";
  import BookDisplay from "@/components/BookDisplay";
  import BookQueries from "@/graphql/BookQueries";

  export default {
    name: "BookshelfItems",
    components: {
      BookshelfIcon,
      EmptyBookList,
      BookDisplay,
    },
    data() {
      return {
        loadingItemsCount: 6,
        showBooks: true,
        filterTags: [],
      };
    },
    computed: {
      bookShelfQuery() {
        return BookQueries.getUserBookshelf;
      },
    },
  };
</script>

<style scoped>
  .bookshelf-header-container {
    border-bottom: 1px solid var(--v-lightAccent-base);
  }

  .bookshelf-header-container h1 {
    font-size: 2rem;
    font-weight: 500;
    align-self: flex-end;
    line-height: 1;
  }

  .bookshelf-header-container p {
    font-size: 1rem;
  }

  @media screen and (max-width: 599px) {
    .bookshelf-header-container h1 {
      font-size: 1.25rem;
    }

    .bookshelf-header-container p {
      font-size: 0.75rem;
    }
  }
</style>