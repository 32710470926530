<template>
  <ApolloQuery
    :query="(gql) => getOrderQuery"
    :variables="{ orderId }"
    :skip="!orderId"
    :update="(data) => data.order"
  >
    <template #default="{ result: { data, error }, isLoading }">
      <div v-if="error">
        <apollo-error-alert />
      </div>
      <v-container v-else-if="isLoading">
        <v-skeleton-loader type="article" />
      </v-container>
      <v-container
        v-else-if="data"
        class="pa-0"
      >
        <h1 class="order-complete-header pb-2 pb-md-3">
          {{ $t("checkout.complete") }}
        </h1>
        <div
          class="details-container pa-2 pa-sm-3 pa-md-5 my-4 my-sm-5 my-md-6"
        >
          <h2 class="details-header pb-2 pb-sm-2 pb-md-3 mb-3 mb-sm-4 mb-md-6">
            {{ $t("details") }}
          </h2>
          <div>
            <v-row
              class="details-row"
              no-gutters
            >
              <v-col class="detail-item py-1 col-12 col-sm-6">
                <span class="detail-bold">{{ $t("checkout.orderComplete.number") }}:
                </span>
                {{ data._id }}
              </v-col>
              <v-col class="detail-item py-1 col-12 col-sm-6">
                <span class="detail-bold">{{ $t("checkout.orderComplete.paymentMethod") }}:
                </span>
                {{ getPaymentMethodName(data) }}
              </v-col>
            </v-row>
            <v-row
              class="details-row"
              no-gutters
            >
              <v-col class="detail-item py-1 col-12 col-sm-6 d-flex">
                <span class="detail-bold">
                  {{ $t("checkout.orderComplete.amountPaid") }}:
                </span>
                <price
                  class="ml-1"
                  :book-price="Number(data.totalPriceSet)"
                  :base-currency="data.currency._id"
                />
              </v-col>
              <v-col class="detail-item py-1 col-12 col-sm-6">
                <span class="detail-bold">{{ $t("date") }}: </span>
                {{ formatDate(data.updatedAt) }}
              </v-col>
            </v-row>
            <order-status
              v-if="data?.orderFulfillment"
              class="mt-3"
              :order="data"
            />
          </div>
        </div>
        <v-row
          class="order-books-container mb-4 mb-md-6"
          no-gutters
        >
          <v-col
            v-for="orderLine in data.orderLines.edges.map((edge) => edge.node)"
            :key="orderLine.id"
            class="col-12 col-sm-6 col-md-4 py-2 py-sm-4 book-column"
          >
            <order-book-display-item
              :order-line="orderLine"
              :order-currency="data.currency"
            />
          </v-col>
          <v-spacer style="border-top: 1px solid var(--v-lightAccent-base)" />
        </v-row>
        <v-btn
          v-if="hasAudiobooks(data)"
          class="bookshelf-button"
          elevation="0"
          tile
          color="primary"
          :block="$vuetify.breakpoint.xs"
          :small="$vuetify.breakpoint.xs"
          @click="() => openAppStore()"
        >
          {{ $t("bookInfo.listenOnEkitabu") }}
        </v-btn>
        <v-btn
          v-else
          class="bookshelf-button"
          elevation="0"
          tile
          color="primary"
          :block="$vuetify.breakpoint.xs"
          :small="$vuetify.breakpoint.xs"
          :to="{ name: 'bookshelf' }"
        >
          {{ $t("bookshelf.viewOnBookshelf") }}
          <v-icon
            size="18"
            class="ml-3"
          >
            mdi-arrow-right
          </v-icon>
        </v-btn>
        <div
          v-if="hasAudiobooks(data)"
          class="mt-3"
        >
          <h4>{{ $t('audiobooks.instructions.title') }}</h4>
          <ol>
            <li>{{ $t('audiobooks.instructions.step1') }}</li>
            <li>{{ $t('audiobooks.instructions.step2') }}</li>
            <li>{{ $t('audiobooks.instructions.step3') }}</li>
            <li>{{ $t('audiobooks.instructions.step4') }}</li>
          </ol>
        </div>
        <IosAudiobookWarning
          :order="data"
        />
      </v-container>
    </template>
  </ApolloQuery>
</template>

<script>
import currencyMixins from "@/mixins/currencyMixins";
import OrderBookDisplayItem from "@/components/OrderBookDisplayItem";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import Price from "@/components/Price";
import gql from "graphql-tag";
import { getProviderDetails } from '@/utils/PaymentProviderHelper';
import { FORMATS, AUDIO_APP_STORE_LINK } from '@/utils/Const';
import OrderStatus from './OrderStatus';
import IosAudiobookWarning from '@/components/audioBooks/IosAudiobookWarning';

export default {
  components: {
    OrderBookDisplayItem,
    ApolloErrorAlert,
    Price,
    OrderStatus,
    IosAudiobookWarning,
  },
  mixins: [currencyMixins],
  data() {
    return {
      orderId: this.$route.params.id,
    };
  },
  computed: {
    getOrderQuery() {
      return gql`
        query GetOrder($orderId: ID!) {
          order(id: $orderId) {
            id
            _id
            totalPriceSet
            shippingFeeSet
            updatedAt
            orderFulfillment {
              id
              fulfilledAt
              addressLine1
              addressLine2
              addressPostalCode
              phoneNumber
              altPhoneNumber
              school {
                id
                name
              }
              studentName
              studentClass
              createdAt
              updatedAt
            }
            safaricomMpesaRequests {
              id
              merchantRequestId
              resultCode
            }
            dpoPayments {
              id
              transactionReference
              resultCode
            }
            cashPayments {
              id
              succeededAt
            }
            currency {
              id
              _id
            }
            orderLines(first: 1000) {
              edges {
                node {
                  id
                  productId
                  name
                  preTaxPrice
                  price
                  discount
                  authorFirstName
                  authorLastName
                  format
                  quantity
                  baseCurrency {
                    id
                    _id
                  }              
                }
              }
            }
          }
        }
      `
    },
    formats() {
      return FORMATS;
    }
  },
  methods: {
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = { year: "numeric", month: "short", day: "numeric" };
      return publicationDate.toLocaleDateString("en-us", options);
    },
    getPaymentMethodName(data) {
      const paymentMethod = getProviderDetails(data);
      return this.$t(`paymentMethods.${paymentMethod?.provider || 'none'}`);
    },
    hasAudiobooks(order) {
      const orderLines = order.orderLines?.edges?.map(({ node }) => node);
      return !!orderLines.find(
        (orderLine) => orderLine.format == FORMATS.audio
      );
    },
    openAppStore() {
      window.open(AUDIO_APP_STORE_LINK);
    },
  },
};
</script>

<style scoped>
.order-complete-header {
  border-bottom: 1px solid var(--v-lightAccent-base);
  font-weight: 500;
}

.details-container {
  width: 100%;
  background-color: var(--v-lightestAccent-base);
}

.details-header {
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 1px solid var(--v-lightAccent-base);
}

.detail-item {
  font-size: 1rem;
}

.detail-bold {
  font-weight: bold;
}

.details-row {
  display: flex;
}

.details-row td {
  flex: 1;
}

.border-line {
  display: flex;
  height: 1px;
  background-color: var(--v-lightAccent-base);
  margin-bottom: 16px;
}

.order-books-container {
  border-bottom: 1px solid var(--v-lightAccent-base);
}

.book-column {
  border-top: 1px solid var(--v-lightAccent-base);
}

@media screen and (max-width: 900px) {
  .order-complete-header {
    font-size: 1.375rem;
  }

  .details-header {
    font-size: 1.125rem;
  }

  .detail-item {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 600px) {
  .bookshelf-button {
    justify-content: space-between;
  }

  .order-complete-header {
    font-size: 1.125rem;
  }

  .details-header {
    font-size: 1rem;
  }

  .detail-item {
    font-size: 0.75rem;
  }
}
</style>
