<template>
  <div>
    <success-snackbar
      v-model="snackbar"
      :message="$t('shop.addedToLibrary')"
    />
    <ApolloMutation
      :mutation="(gql) => addToLibraryMutation"
      :variables="{
        productId: productId,
        locale: countryCode,
      }"
      :refetch-queries="refetchBookshelfQuery"
      @done="onDone"
    >
      <template #default="{ mutate, loading }">
        <v-btn
          color="primary"
          tile
          :disabled="loading || queryLoading || !isLoggedIn || !isVerified"
          :loading="loading || queryLoading"
          elevation="0"
          :small="$vuetify.breakpoint.md || $vuetify.breakpoint.sm"
          :x-small="$vuetify.breakpoint.xs"
          @click="mutate"
        >
          {{ isLoggedIn && isVerified ? $t("shop.addToLibrary") : isLoggedIn ? $t("shop.verifyToAdd") : $t("shop.loginToAdd") }}
          <bookshelf-icon
            color="white"
            style="height: 17px; margin-left: 10px;"
          />
        </v-btn>
      </template>
    </ApolloMutation>
  </div>
</template>

<script>
import BookshelfIcon from "@/components/icons/BookshelfIcon.vue";
import OrderQueries from "@/graphql/OrderQueries";
import SuccessSnackbar from '@/components/SuccessSnackbar.vue';
import BookQueries from "@/graphql/BookQueries";

export default {
  name: "AddToLibraryButton",
  components: {
    SuccessSnackbar,
    BookshelfIcon,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    queryLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: false,
    }
  },
  computed: {
    addToLibraryMutation() {
      return OrderQueries.addToLibrary;
    },
    refetchBookshelfQuery() {
      return () => [{
        query: BookQueries.getUserBookshelf,
      }]
    },
    isLoggedIn() {
      return this.$auth.isAuthenticated;
    },
    isVerified() {
      return this.$auth.user.email_verified;
    },
    countryCode() {
      return this.$store.state.locale.countryCode;
    },
  },
  methods: {
    onDone() {
      this.snackbar = true;
    },
  },
}
</script>