<template>
  <div>
    <v-btn
      v-bind="$attrs"
      tile
      color="primary"
      elevation="0"
      :small="$vuetify.breakpoint.md || $vuetify.breakpoint.sm"
      :x-small="$vuetify.breakpoint.xs"
      :disabled="!isAvailable"
      @click="openBook()"
    >
      {{
        isAudio ? audioText :
        isAvailable ? $t("bookInfo.openBook") :
        $t("bookInfo.notAvailable")
      }}
    </v-btn>
  </div>
</template>

<script>
import { FORMATS, AUDIO_APP_STORE_LINK } from "@/utils/Const";
import i18n from '@/i18n';

export default {
  name: "OpenBookButton",
  props: {
    readUrl: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
    audioText: {
      type: String,
      required: false,
      default: i18n.t("bookInfo.listenOnEkitabu"),
    }
  },
  computed: {
    isAudio() {
      return this.format == FORMATS.audio;
    },
    isAvailable() {
      return !!this.readUrl || this.isAudio;
    },
  },
  methods: {
    async openBook() {
      if (this.isAudio) {
        window.open(AUDIO_APP_STORE_LINK);
      } else {
        window.open(this.readUrl);
      }
    },
  },
};
</script>
