var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-container"},[_c('v-img',{staticClass:"pattern-layer",attrs:{"src":require("@/assets/img/logo_pattern_corner.png")}}),_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"book-thumbnails ml-4"},_vm._l((_vm.bookThumbnails),function(thumbnail,index){return _c('div',{key:thumbnail,class:'book-thumbnail-container '+(index % 2 === 0 ? 'pt-0' : 'pt-7 pt-md-11')},[_c('v-img',{staticClass:"book-thumbnail",attrs:{"src":require(`@/assets/img/thumbnails/${thumbnail}.jpg`),"object-fit":"contain"}})],1)}),0),_c('div',{staticClass:"banner-text"},[_c('h1',{staticClass:"banner-title"},[_vm._v(" "+_vm._s(_vm.$t("audioBanner.title"))+" ")]),_c('h3',{staticClass:"banner-subtitle mb-6"},[_vm._v(" "+_vm._s(_vm.$t("audioBanner.subtitle"))+" ")]),_c('h3',{staticClass:"banner-description mb-4",domProps:{"innerHTML":_vm._s(_vm.$t('audioBanner.description'))}}),_c('v-btn',{staticClass:"view-button",attrs:{"color":"primary","elevation":"0","tile":""},on:{"click":function($event){_vm.$router.push({
            name: 'filteredBookList',
            params: {
              id: _vm.audiobookTagId,
              tagName: _vm.$t('tags.' + _vm.audiobookTagId),
            },
          })}}},[_vm._v(" "+_vm._s(_vm.$t("audioBanner.view"))+" "),_c('v-icon',{attrs:{"right":"","small":"","color":"textOnPrimary"}},[_vm._v(" $basket ")])],1)],1)]),_c('v-icon',{staticClass:"audio-icon",attrs:{"size":"38"}},[_vm._v(" $audiobook ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }