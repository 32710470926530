<template>
  <v-footer
    class="pa-0 flex-column"
    color="footerBackground"
    dark
    fixed
    inset
    app
  >
    <div class="admin-button-container">
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown &&
          isAdmin && $route.name != 'adminDashboard'"
        fab
        tile
        color="primary"
        class="admin-button"
        :to="{ name: 'adminDashboard' }"
      >
        {{ $t('menu.admin') }}
      </v-btn>    
    </div>
    <v-expansion-panels
      :disabled="$vuetify.breakpoint.mdAndUp"
      :value="$vuetify.breakpoint.mdAndUp ? 0 : -1"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          v-if="$vuetify.breakpoint.smAndDown"
          :hide-actions="$vuetify.breakpoint.mdAndUp"
          class="expansion-header pa-1"
          expand-icon="mdi-chevron-up"
        />
        <v-expansion-panel-content class="pa-0 pt-4 pt-md-6">
          <v-row
            justify="space-between"
            align="center"
            class="footer-row pa-0"
          >
            <v-col
              cols="auto"
              class="footer-options-column"
            >
              <footer-links :links="helpLinks" />
              <div class="footer-options-divider mt-2" />
              <footer-select-options class="justify-center" />
            </v-col>
            <v-col
              cols="auto"
              class="copyright-text-container"
            >
              <footer-links :links="legalLinks" />
              <div class="footer-options-divider mt-2" />
              <div class="copyright-container pt-1">
                {{ $t("footer.copyright", { year: copyrightYearSpanText }) }}
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-footer>
</template>

<script>
import FooterLinks from "@/components/footer/FooterLinks";
import FooterSelectOptions from "@/components/footer/FooterSelectOptions";
import AdminCheckMixin from '@/mixins/AdminCheckMixin';
import { SUPPORT_URL, TERMS_OF_SERVICE_URL, PRIVACY_POLICY_URL } from '@/utils/Const';

export default {
  name: "EkitabuFooter",
  components: {
    FooterLinks,
    FooterSelectOptions,
  },
  mixins: [AdminCheckMixin],
  data() {
    return {
      helpLinks: [
        { name: "contact", url: null },
        { name: "help", url: SUPPORT_URL },
      ],
      legalLinks: [
        { name: "privacyPolicy", url: PRIVACY_POLICY_URL },
        { name: "refundPolicy", url: null },
        { name: "termsOfService", url: TERMS_OF_SERVICE_URL },
      ],
    };
  },
  computed: {
    copyrightYearSpanText() {
      let createdDate = "2020";
      let date = new Date().getFullYear();
      if (date == createdDate) {
        return date;
      } else {
        return createdDate + " - " + date;
      }
    },
  },
};
</script>

<style scoped>
.footer-options-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-options-divider {
  height: 1px;
  width: 100%;
  background-color: var(--v-background-base);
  opacity: 0.2;
}

.expansion-header {
  min-height: 0px;
}

.expansion-header > .v-expansion-panel-header__icon {
  margin: auto !important;
}

.admin-button-container {
  position: relative;
  width: 100%;
}

.admin-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.copyright-container {
  height: 32px;
}

@media screen and (max-width: 959px) {
  .footer-row {
    display: block;
  }

  .footer-links-column {
    justify-content: center;
  }

  .footer-options-column {
    justify-content: center;
  }

  .copyright-text-container {
    text-align: center;
    margin-top: 10px;
    font-size: 10px;
    opacity: 80%;
  }
}
</style>