<template>
  <ApolloMutation
    :mutation="addBookListToCartMutation"
    :variables="{
      itemIds: booksToAdd,
      orderIri: $store.state.cart.currentCart,
      locale: countryCode,
    }"
    @done="onDone"
  >
    <template #default="{ loading, mutate }">
      <v-btn
        tile
        elevation="0"
        color="primary"
        class="px-2 px-sm-3"
        :small="$vuetify.breakpoint.xs"
        :disabled="$apollo.loading || !!loading"
        :loading="$apollo.loading || !!loading"
        @click="handleAddToCart(mutate)"
      >
        {{ booksToAdd.length ? $t("bookList.addListToCart") : $t("shop.viewCart") }}
        <v-icon
          v-if="booksToAdd.length"
          class="ml-2"
          :size="$vuetify.breakpoint.xs ? 14 : 18"
        >
          $basket
        </v-icon>
      </v-btn>
      <success-snackbar
        v-model="snackbar"
        :message="$t('shop.addedToCart')"
      />
    </template>
  </ApolloMutation>
</template>

<script>
import OrderQueries from '@/graphql/OrderQueries';
import BookQueries from '@/graphql/BookQueries';
import SuccessSnackbar from '@/components/SuccessSnackbar';

export default {
  name: "AddBookListToCartButton",
  components: {
    SuccessSnackbar,
  },
  props: {
    bookList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      snackbar: false,
      itemsInCart: [],
      itemsInBookshelf: [],
    };
  },
  computed: {
    booksToAdd() {
      const booksToAdd = [];
      this.bookList.forEach((book) => {
        if (!this.itemsInCart?.includes(book) && !this?.itemsInBookshelf?.includes(book)) {
          booksToAdd.push(book);
        }
      });

      return booksToAdd;
    },
    addBookListToCartMutation() {
      return OrderQueries.addMultipleItemsToOrder;
    },
    currentCart() {
      return this.$store.state.cart.currentCart;
    },
    countryCode() {
      return this.$store.state.locale.countryCode;
    },
    isLoggedIn() {
      return this.$auth.isAuthenticated;
    },
  },
  apollo: {
    order: {
      query: OrderQueries.getOrder,
      variables() {
        return {
          orderId: this.currentCart,
        };
      },
      skip() {
        return !this.currentCart;
      },
      update(data) {
        if (data) {
          this.itemsInCart = data.order.orderLines.edges.map((edge) => edge.node.productId);
        }
      },
    },
    books: {
      query: BookQueries.getUserBookshelf,
      skip() {
        return !this.isLoggedIn;
      },
      update(data) {
        if (data) {
          this.itemsInBookshelf = data.books.collection.map((item) => item._id);
        }
      },
    },
  },
  methods: {
    handleAddToCart(mutate) {
      if (this.booksToAdd.length) {
        mutate();
      } else {
        this.$router.push({ name: "cart" });
      }
    },
    onDone({ data }) {
      if (!this.currentCart) {
        this.$store.commit("setCurrentCart", data.addMultipleItemsToOrder.order.id);
      }

      this.$router.push({ name: "cart" });
    }
  },
};
</script>