export default Object.freeze({
  book: "/books/",
  bookList: "/book_lists/",
  order: "/orders/",
  event: "/events/",
  school: "/schools/",
});

export function removeIriId(id) {
  return id.split("/").pop();
}
