var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('success-snackbar',{attrs:{"message":_vm.$t('shop.addedToCart')},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}}),_c('ApolloMutation',{attrs:{"mutation":(gql) => _vm.addItemToOrderQuery,"variables":{
      itemId: _vm.id,
      orderId: _vm.orderId,
      locale: _vm.countryCode,
    },"update":_vm.updateStore},on:{"done":_vm.onDone},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [(!_vm.inCart || !_vm.proceedToCheckout)?_c('v-btn',_vm._b({attrs:{"color":"primary","tile":"","disabled":loading || _vm.queryLoading,"loading":loading || _vm.queryLoading,"elevation":"0","small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm,"x-small":_vm.$vuetify.breakpoint.xs},on:{"click":function($event){$event.stopPropagation();return (() => {
          if (!_vm.canAdd) {
            _vm.$router.push({ name: 'cart' });
          }
          else {
            mutate();
          }
        }).apply(null, arguments)}}},'v-btn',_vm.$attrs,false),[_vm._v(" "+_vm._s(_vm.inCart && _vm.canAdd ? (_vm.$t('shop.addToCart')+` (${_vm.quantity})`) : _vm.inCart ? _vm.inCartText : _vm.addToCartText)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"18"}},[_vm._v(" $basket ")])],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }