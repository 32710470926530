<template>
  <v-expansion-panels
    accordion
    :value="0"
  >
    <v-expansion-panel class="details-dropdown-container">
      <v-expansion-panel-header
        class="v-card__title"
        expand-icon="mdi-menu-down"
      >
        {{ $t("bookInfo.bookDetails") }}
      </v-expansion-panel-header>
      <v-expansion-panel-content class="dropdown-content">
        <v-card
          tile
          outlined
          class="book-details-card"
        >
          <div class="book-details-table-container">
            <table class="book-details-table">
              <tr v-if="book.title">
                <td>{{ $t("bookInfo.title") }}</td>
                <td>{{ decodeHtml(book.title) }}</td>
              </tr>
              <tr v-if="book.modified">
                <td>{{ $t("bookInfo.published") }}</td>
                <td>{{ publicationDate(book.modified) }}</td>
              </tr>
              <tr v-if="book.publisher">
                <td>{{ $t("bookInfo.publisher") }}</td>
                <td>{{ book.publisher }}</td>
              </tr>
              <tr v-if="book.language">
                <td>{{ $t("bookInfo.language") }}</td>
                <td>{{ book.language }}</td>
              </tr>
              <tr v-if="book.format">
                <td>{{ $t("bookInfo.format") }}</td>
                <td>{{ formattedAndTranslated }}</td>
              </tr>
              <tr v-if="book.isbn">
                <td>{{ $t("bookInfo.isbn") }}</td>
                <td>{{ book.isbn }}</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { decodeHtml } from "@/helpers/generalFunctions";

export default {
  name: "BookDetails",
  props: {
    book: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    formattedAndTranslated() {
      const formatText = this?.book?.format.replace("application/", "");
      const translationKey = `formats.${formatText}`;
      return this.$te(translationKey) ? this.$t(translationKey) : formatText;
    },
  },
  methods: {
    publicationDate(date) {
      let publicationDate = new Date(date);
      return publicationDate.getFullYear();
    },
    decodeHtml(html) {
      return decodeHtml(html);
    },
  },
};
</script>

<style>
.details-dropdown-container {
  border-radius: 0px;
}

.details-dropdown-container button {
  border-radius: 0px;
  padding: 10px 12px;
  color: var(--v-textOnPrimary-base);
  min-height: 38px;
}

.details-dropdown-container .v-expansion-panel-header--active {
  min-height: 38px !important;
}

.details-dropdown-container::before {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px 0px;
}

.details-dropdown-container .v-expansion-panel-header__icon i {
  color: var(--v-textOnPrimary-base) !important;
}

.dropdown-content .v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.v-card__title {
  background-color: var(--v-primary-base);
  color: var(--v-textOnPrimary-base);
  font-size: 18px;
}

table {
  border: none;
  border-collapse: collapse;
}

.book-details-table tr td {
  vertical-align: top;
  padding: 10px;
  font-size: 14px;
}

.book-details-table tr td:first-child {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  width: 55px;
  border-right: 1px solid var(--v-lightAccent-base);
  padding-top: 13px;
}

.book-details-table tr:first-child td {
  padding-top: 0px;
}

.book-details-table tr:first-child td:first-child {
  padding-top: 2px;
}

.book-details-table tr td:last-of-type {
  word-break: break-word;
}

.book-details-table tr:last-child td {
  padding-bottom: 0;
}

.book-details-table-container {
  border: 1px solid var(--v-lightAccent-base);
  border-top: 0;
  height: 100%;
  padding: 12px 0;
}

@media screen and (max-width: 599px) {
  .book-details-card {
    margin: 0px auto;
  }
}
</style>
