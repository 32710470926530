<!-- eslint-disable -->
<template>
  <div class="banner-container">
    <v-img
      src="@/assets/img/logo_pattern_corner.png"
      class="pattern-layer"
    />
    <div class="banner-content">
      <div class="book-thumbnails ml-4">
        <div
          v-for="(thumbnail, index) in bookThumbnails"
          :key="thumbnail"
          :class="
            'book-thumbnail-container '+(index % 2 === 0 ? 'pt-0' : 'pt-7 pt-md-11')
          "
        >
          <v-img
            :src="require(`@/assets/img/thumbnails/${thumbnail}.jpg`)"
            class="book-thumbnail"
            object-fit="contain"
          />
        </div>
      </div>
      <div class="banner-text">
        <h1 class="banner-title">
          {{ $t("audioBanner.title") }}
        </h1>
        <h3 class="banner-subtitle mb-6">
          {{ $t("audioBanner.subtitle") }}
        </h3>
        <h3
          class="banner-description mb-4"
          v-html="$t('audioBanner.description')"
        />
        <v-btn
          color="primary"
          elevation="0"
          tile
          class="view-button"
          @click="
            $router.push({
              name: 'filteredBookList',
              params: {
                id: audiobookTagId,
                tagName: $t('tags.' + audiobookTagId),
              },
            })
          "
        >
          {{ $t("audioBanner.view") }}
          <v-icon
            right
            small
            color="textOnPrimary"
          >
            $basket
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-icon
      size="38"
      class="audio-icon"
    >
      $audiobook
    </v-icon>
  </div>
</template>

<script>
import { AUDIOBOOK_TAG_ID } from "@/utils/Const";

export default {
  name: "AudiobookBanner",
  computed: {
    bookThumbnails() {
      return [
        "beautiful_mess",
        "whispers_from_vera",
        "cocktail_from_the_savanna",
        "three_bolts_from_the_blue",
      ];
    },
    audiobookTagId() {
      return AUDIOBOOK_TAG_ID;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.banner-container {
  position: relative;
  background: radial-gradient(233.13% 233.13% at 67.42% 67.13%, #000000 0%, #41080A 31.88%, #ED1C24 100%);
  background-size: cover;
  min-height: 280px;
  width: 100vw;
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  padding: 40px;
}

.pattern-layer {
  position: absolute;
  width: 280px;
  bottom: 0;
  right: 0px;
}

.banner-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  justify-content: flex-start;
  align-items: center;
  gap: 70px;
  margin: 0 auto;
}

.book-thumbnail-container {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.book-thumbnails {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 183px;
}

.book-thumbnail {
  height: auto;
  object-fit: contain;
  box-shadow: 0px 51px 33.1px -7px #ff000040;
}

.banner-text {
  color: var(--v-textOnPrimary-base);
  max-width: 673px;
}

.banner-title,
.banner-subtitle,
.view-button {
  font-weight: 700;
}

.banner-title {
  font-size: 36px;
}

.banner-text h3 {
  font-size: 16px;
}

.banner-subtitle {
  text-transform: uppercase;
}

.banner-description {
  font-weight: 400;
}

.audio-icon {
  position: absolute;
  bottom: 14px;
  left: 25px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .banner-container {
    padding: 20px;
  }

  .banner-content {
    flex-direction: column;
    gap: 20px;
  }

  .book-thumbnails {
    gap: 5px;
    height: auto;
    align-items: flex-start;
  }

  .book-thumbnail {
    max-width: 75px;
    box-shadow: 0px 31px 23.1px -7px #ff000040;
  }

  .audio-icon {
    bottom: auto;
    top: 14px;
  }
}
</style>
