<template>
  <div>
    <h4>{{ $t('checkout.adminOrderDetails') }}</h4>
    <ekitabu-masked-phone-input
      v-if="showPhoneInput"
      id="phone-input"
      :value="accountPhone"
      class="mt-2"
      :label="$t('checkout.form.phoneNumber')"
      :rules="(isCashTransaction ? '' : 'required|')+'max:9|min:9'"
      placeholder="### ### ###"
      type="tel"
      :required="!isCashTransaction"
      @formattedPhoneChanged="setFormattedPhone"
    />
    <ekitabu-text-input
      id="email-input"
      v-model="forEmail"
      class="mt-2"
      :label="$t('profile.email')"
      :placeholder="$t('checkout.form.emailExample')"
      rules="email"
      type="email"
    />
    <ekitabu-text-input
      id="name-input"
      v-model="forName"
      class="mt-2"
      :label="$t('checkout.form.fullName')"
      :placeholder="$t('checkout.form.fullName')"
      rules="min:3|max:50"
    />
    <ekitabu-event-select
      v-model="event"
      class="mt-2"
    />
  </div>
</template>

<script>
import { required, max, min, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from "@/i18n";
import EkitabuMaskedPhoneInput from "@/components/EkitabuMaskedPhoneInput";
import EkitabuTextInput from "@/components/EkitabuTextInput";
import EkitabuEventSelect from "@/components/inputs/EkitabuEventSelect";

extend("required", {
  ...required,
  message: i18n.t("errors.required", { field: "{_field_}" }),
});

extend("max", {
  ...max,
  message: i18n.t("errors.maxLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("min", {
  ...min,
  message: i18n.t("errors.minLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("email", {
  ...email,
  message: i18n.t("errors.email", { field: "{_field_}" }),
});

export default {
  name: "AdminOrder",
  components: {
    EkitabuMaskedPhoneInput,
    EkitabuTextInput,
    EkitabuEventSelect,
  },
  props: {
    showPhoneInput: {
      type: Boolean,
      default: true,
    },
    isCashTransaction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accountPhone: "",
      formattedPhone: "",
      forEmail: "",
      forName: "",
      event: null,
    };
  },
  watch: {
    formattedPhone() {
      this.updateAdminOrderDetails();
    },
    forEmail() {
      this.updateAdminOrderDetails();
    },
    forName() {
      this.updateAdminOrderDetails();
    },
    event(val) {
      this.updateAdminOrderDetails();
    },
  },
  methods: {
    setFormattedPhone(value) {
      this.formattedPhone = value;
    },
    updateAdminOrderDetails() {
      this.$emit("updateAdminOrderDetails", {
        forEmail: this.forEmail,
        forName: this.forName,
        ...(this.showPhoneInput && { phoneNumber: this.formattedPhone }),
        eventId: this.event,
      });
    },
  },
};
</script>