<template>
  <div class="input-container">
    <div class="d-flex">
      <ekitabu-checkbox
        v-if="useCheckbox"
        v-model="enabled"
      />
      <label
        v-if="label"
        class="input-label"
        :for="id"
      >
        {{ label }}
        <span
          v-if="required"
          class="required"
        >*</span>
      </label>
    </div>
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
      :name="label"
      mode="aggressive"
    >
      <v-select
        :id="id"
        v-bind="$attrs"
        v-model="currentValue"
        :items="items"
        hide-details
        class="input-select"
        :disabled="!enabled"
        solo
        flat
        dense
        tile
      />
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="error-text"
      >
        {{ error }}
      </span>
    </validation-provider>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate'

  export default {
    name: "EkitabuSelect",
    components: {
      ValidationProvider,
    },
    props: {
      value: {
        type: [String, Number, Boolean, Object, Array],
        default: null,
      },
      items: {
        type: Array,
        required: true,
      },
      label: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
        required: true,
      },
      rules: {
        type: String,
        default: null,
      },
      useCheckbox: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentValue: this.value,
        enabled: this.disabled ? false : this.useCheckbox ? false : true,
      }
    },
    watch: {
      currentValue(val) {
        this.$emit("input", val);
      },
      value(val) {
        this.currentValue = val;
      },
      disabled(val) {
        this.enabled = val ? false : true;
      },
      enabled(val) {
        if (!val) {
          this.currentValue = null;
        }
      },
    },
  };
</script>

<style scoped>
  .input-select {
    height: 48px;
    display: flex;
    align-items: center;
  }

  .input-container {
    max-width: 300px;
  }

  .input-label {
    font-size: 0.875rem;
    font-weight: normal;
    color: var(--v-secondaryText-base);
    padding-bottom: 6px;
  }

  @media screen and (max-width: 599px) {
    .input-label {
      font-size: 0.688rem;
    }
    .input-select {
      font-size: 0.75rem;
    }
  }
</style>