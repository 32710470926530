<template>
  <ApolloQuery
    :query="(gql) => bookListQuery"
    :variables="{
      id: bookListId,
      orderDirection: orderAscending ? 'ASC' : 'DESC',
    }"
    :update="(data) => data.bookList"
  >
    <template #default="{ result: { error, data }, isLoading }">
      <success-snackbar
        v-model="snackbar"
        :message="$t('bookList.removedFromList')"
      />
      <v-container class="pa-0">
        <div class="book-list-header-container mb-6 pb-3">
          <div class="d-flex align-center mb-4">
            <book-list-icon
              :size="24"
              color="var(--v-primary-base)"
              class="mr-3"
            />
            <v-skeleton-loader
              v-if="isLoading"
              type="heading"
              width="400"
              class="mb-0"
            />
            <h1
              v-else
              class="book-list-header"
            >
              {{ data?.name }}
            </h1>
            <v-spacer />
            <add-book-list-to-cart-button
              :book-list="data?.bookListItems?.map((item) => item.productId)"
              class="ml-2"
            />
          </div>
          <div class="d-flex justify-sm-end my-3">
            <share-list-buttons
              v-if="data"
              :is-owner="userId === data?.customerId" 
              :book-list-id="$route.params.id"
              :is-public="data?.isPublic ?? false"
              :book-list-name="data?.name"
            />
          </div>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-btn
                v-if="isLoggedIn && userId === data?.customerId"
                tile
                elevation="0"
                color="secondary"
                :small="$vuetify.breakpoint.xs"
                @click="toggleEditing()"
              >
                {{ !editingList ? $t("bookList.editList") : $t("bookList.stopEditing") }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <sort-button
                v-model="orderAscending"
                class="ml-0"
              />
            </v-col>
          </v-row>
        </div>
        <apollo-error-alert
          v-if="error"
          class="mt-3"
          :error="error"
        />
        <v-row
          v-else-if="isLoading"
        >
          <v-col
            v-for="n in loadingItemsCount"
            :key="n"
            class="col-4 col-sm-3 col-md-2 pa-1 pa-sm-2 pa-md-3"
          >
            <v-skeleton-loader
              type="card"
            />                    
          </v-col> 
        </v-row>
        <v-row
          v-else
        >
          <book-list-item
            v-for="book in data.bookListItems"
            :id="'/books/'+book.productId"
            :key="book.productId"
            :book="book"
            :price="book.basePrice"
            :thumbnail-url="book.thumbnailUrl"
            :title="book.name"
            :currency="book.baseCurrency"
            :is-owned="checkInBookshelf('/books/'+book.productId) != null"
          >
            <v-btn
              v-if="editingList && isLoggedIn && userId === data?.customerId"
              fab
              small
              class="remove-button"
              :loading="itemToRemove == book.id && removeIsLoading"
              @click="removeBookFromList(book.id)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>          
          </book-list-item>
        </v-row>
        <empty-book-list v-if="(!data || data?.bookListItems?.length === 0) && !isLoading" />
      </v-container>
    </template>
  </ApolloQuery>
</template>

<script>
import BookListIcon from "@/components/icons/BookListIcon";
import EmptyBookList from '@/components/EmptyBookList';
import BookListQueries from "@/graphql/BookListQueries";
import BookListItem from "@/components/bookList/BookListItem";
import SuccessSnackbar from "@/components/SuccessSnackbar";
import AddBookListToCartButton from '@/components/AddBookListToCartButton';
import SortButton from '@/components/SortButton';
import ShareListButtons from "@/components/bookList/ShareListButtons";
import iris from "@/iris";
import gql from "graphql-tag";
import BookshelfMixin from "@/mixins/BookshelfMixin";

export default {
  components: {
    BookListIcon,
    EmptyBookList,
    BookListItem,
    SuccessSnackbar,
    AddBookListToCartButton,
    SortButton,
    ShareListButtons,
  },
  mixins: [BookshelfMixin],
  data() {
    return {
      loadingItemsCount: 6,
      editingList: false,
      snackbar: false,
      orderAscending: true,
      removeIsLoading: false,
      itemToRemove: null,
    };
  },
  computed: {
    bookListId() {
      return iris.bookList+this.$route.params.id;
    },
    bookListQuery() {
      return gql`
        query getBookList($id: ID!, $orderDirection: String) {
          bookList(id: $id) {
            id
            customerId
            name
            isPublic
            bookListItems(order: {name: $orderDirection}) {
              id
              productId
              name
              thumbnailUrl
              basePrice
              baseCurrency {
                id
                _id
              }
            }
          }
        }
      `
    },
    isLoggedIn() {
      return this.$auth.isAuthenticated;
    },
    userId() {
      return this.$auth?.user?.sub.toString();
    },
  },
  methods: {
    toggleEditing() {
      this.editingList = !this.editingList;
    },
    async removeBookFromList(bookListItemId) {
      this.removeIsLoading = true;
      this.itemToRemove = bookListItemId;
      const result = await this.$apollo.mutate({
        mutation: BookListQueries.deleteBookListItem,
        variables: {
          id: bookListItemId,
        },
        update: (cache, result) => {
          const data = cache.readQuery({
            query: this.bookListQuery,
            variables: {
              id: this.bookListId,
              orderDirection: this.orderAscending ? 'ASC' : 'DESC',
            }
          })

          cache.modify({
            id: cache.identify(data.bookList),
            fields: {
              bookListItems(existingItems, { readField }) {
                return existingItems.filter((item) => readField('id', item) !== bookListItemId);
              }
            }
          })
        }
      });

      if (result.data) {
        this.snackbar = true;
      }

      this.removeIsLoading = false;
      this.itemToRemove = null;
    },
  }
};
</script>

<style scoped>
.book-list-header-container {
  border-bottom: 1px solid var(--v-lightAccent-base);
}

.book-list-header {
  font-size: 1.5rem;
  font-weight: 500;
}

.remove-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

@media screen and (max-width: 899px) {
  .book-list-header {
    font-size: 1.375rem;
  }
}

@media screen and (max-width: 599px) {
  .book-list-header {
    font-size: 1.125rem;
  }
}
</style>
