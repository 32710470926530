import gql from 'graphql-tag';

export default {
  name: 'BookshelfMixin',
  data() {
    return {
      bookshelf: [],
    };
  },
  apollo: {
    books: {
      query: gql`
        query getUserBookshelf {
          books(myBookshelf: true) {
            collection {
              id
              _id
              title
              authors
              thumbnailUrl
              readUrl
            }
          }
        }
      `,
      skip() {
        return !this.$auth.isAuthenticated || !this.$auth?.user?.email_verified || !this.getMe
      },
    },
  },
  computed: {
    getMe() {
      return this.$store.state.auth.getMe;
    },
  },
  methods: {
    checkInBookshelf(id) {
      return this.books?.collection?.find((book) => book.id === id);
    }
  },
}