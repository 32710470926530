<template>
  <div>
    <div class="header-border pb-1 mb-3">
      <h2>{{ $t('eventManager.events') }}</h2>
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <v-list
      class="event-list"
    >
      <v-list-item-group
        v-if="events?.length > 0"
        v-model="selectedEventId"
      >
        <v-list-item
          v-for="event in events"
          :key="event.id"
          :value="event.id"
        >
          <v-list-item-title>{{ event.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item
        v-else
      >
        <v-list-item-title>{{ $t('eventManager.noEvents') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "EventList",
  data() {
    return {
      selected: null,
      selectedEventId: null,
    };
  },
  apollo: {
    events: {
      query: gql`
        query Events {
          events {
            edges {
              node {
                id
                _id
                name
              }
            }
          }
        }
      `,
      update: (result) => {
        return result?.events?.edges.map((edge) => edge.node);
      },
    },
  },
  watch: {
    selectedEventId(val) {
      this.$emit("eventSelected", val);
    },
  },
};
</script>

<style scoped>
.event-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>