import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { icons } from "./icons";

Vue.use(Vuetify);

const palette = {
  grey: {
    lightest: "#f6f6f6",
    lighter: "#e4e4e4",
    light: "#d3d3d3",
    mediumLight: "#b6b6b6",
    normal: "#797979",
    normalDark: "65686b",
    dark: "#808080",
    darker: "#212121"
  },
  yellow: {
    normal: "#F0A617"
  },
  red: {
    normal: "#ed1c24",
  },
  purple: {
    normal: "#A14572",
  },
  blue: {
    normal: "#0976B4",
  },
  white: {
    normal: "#ffffff",
    offWhite: "#fbfbfb",
  },
  black: "#000",
};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: palette.red.normal,
        secondary: palette.grey.normal,
        tertiary: palette.blue.normal,
        background: palette.white.normal,
        backgroundAccent: palette.grey.light,
        backgroundSecondary: palette.white.offWhite,
        primaryText: palette.black,
        secondaryText: palette.grey.mediumLight,
        tertiaryText: palette.grey.normalDark,
        textOnPrimary: palette.white.normal,
        textOnError: palette.white.normal,
        accent: palette.grey.light,
        lightAccent: palette.grey.lighter,
        lightestAccent: palette.grey.lightest,
        footerBackground: palette.grey.darker,
        featuredBookPurple: palette.purple.normal,
        featuredBookYellow: palette.yellow.normal,
      },
    },
  },
  breakpoint: {
    threshholds: {
      xs: 600,
      sm: 900,
    },
  },
  icons,
});
