<template>
  <v-badge
    :content="cartItemCount"
    :value="orderId && cartItemCount > 0"
    color="primary"
    overlap
    offset-x="20"
  >
    <router-link
      class="underline-link-container"
      :to="{ name: 'cart' }"
      style="padding-right: 10px; padding-left: 10px"
    >
      <v-icon color="primaryText">
        $basket
      </v-icon>
    </router-link>
  </v-badge>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CartBadge",
  components: {
  },
  data() {
    return {
      cartItemCount: 0,
    };
  },
  apollo: {
    order: {
      query: gql`
        query getCartItems($orderId: ID!) {
          order(id: $orderId) {
            id
            orderLines(first: 1000) {
              edges {
                node {
                  id
                }
              }
            }
            confirmed
          }
        }
      `,
      variables() {
        return {
          orderId: this.orderId,
        };
      },
      skip() {
        return !this.orderId;
      },
      update(data) {
        if (data.order) {
          this.cartItemCount = data.order.orderLines.edges.length;
        }
        
        return data;
      },
    },
  },
  computed: {
    orderId() {
      return this.$store.state.cart.currentCart;
    },
  },
};
</script>
