<template>
  <div>
    <div class="d-flex">
      <ekitabu-checkbox
        v-if="useCheckbox"
        v-model="enabled"
      />
      <label
        v-if="label"
        class="input-label"
        :for="id"
      >
        {{ label }}
        <span
          v-if="required"
          class="required"
        >*</span>
      </label>
    </div>
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
      :name="label"
    >
      <v-autocomplete
        :id="id"
        ref="searchBar"
        v-model="selected"
        v-bind="$attrs"
        :disabled="!enabled"
        :search-input.sync="searchText"
        class="input-field searchbox"
        :items="searchTextValid ? items : []"
        prepend-inner-icon="search"
        :loading="loading"
        :allow-overflow="false"
        hide-spin-buttons
        flat
        hide-details
        cache-items
        solo
        dense
        tile
        clearable
        :label="placeholder"
        hide-no-data
        :item-value="itemValue"
        :item-text="selectText"
      />
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="error-text"
      >
        {{ error }}
      </span>        
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import EkitabuCheckbox from '@/components/EkitabuCheckbox';
import { debounce } from "debounce";

export default {
  name: "SearchAutocomplete",
  components: {
    ValidationProvider,
    EkitabuCheckbox,
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null
    },
    itemValue: {
      type: String,
      default: "id"
    },
    selectText: {
      type: Function,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: null
    },
    useCheckbox: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      searchText: "",
      selected: this.value,
      enabled: this.useCheckbox && !this.value ? false : true,
    };
  },
  computed: {
    searchTextValid() {
      return this.searchText != null && this.searchText.length > 2;
    }
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.selected = val;
    },
    searchText(val) {
      if (this.searchTextValid) {
        this.debounceInput(val);
      }
    },
    enabled(val) {
      if (!val) {
        this.selected = null;
      }
    },
  },
  methods: {
    debounceInput: debounce(function (val) {
      this.$emit("searchChanged", val);
    }, 500),
  },
};
</script>

<style scoped>
  .searchbox {
    border-radius: 0px;
    border: 1px solid var(--v-lightAccent-base);
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 4px 0px;
  }

  .input-label {
    font-size: 0.875rem;
    font-weight: normal;
    color: var(--v-secondaryText-base);
    padding-bottom: 6px;
  }
</style>
