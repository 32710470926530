<template>
  <v-container class="pa-0">
    <ApolloQuery
      ref="usersQuery"
      :query="(gql) => usersQuery"
      :variables="{
        page: currentPage,
        perPage: itemsPerPage,
        sort: sortString,
        filterQuery: filterQuery,
        hasOrders: hasOrders,
        ...(filterLastOrderDate && {
          filterOrderDateStart: filterDates.startDate ?? null,
          filterOrderDateEnd: filterDates.endDate ?? null,
        }),
      }"
      :update="updateData"
    >
      <template #default="{ result: { error, data }, isLoading }">
        <apollo-error-alert v-if="error" />
        <v-data-table
          v-model="selectedRows"
          v-bind="$attrs"
          :items="data ?? []"
          item-key="id"
          :headers="customHeaders"
          class="data-table-container mb-6"
          disable-filtering
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': $t('common.itemsPerPage'),
          }"
          :loading="!!isLoading"
          :show-select="showSelect"
          :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
          :server-items-length="totalCount"
          :items-per-page.sync="itemsPerPage"
          :loading-text="$t('common.loading')"
          :no-data-text="$t('common.noData')"
          :page="currentPage+1"
          @update:page="($event) => setPage($event)"
          @update:items-per-page="($event) => setItemsPerPage($event)"
          @update:sort-by="($event) => updateSort('by', $event)"
          @update:sort-desc="($event) => updateSort('desc', $event)"
          @click:row="(item, slot) => navigateToCustomer ? $router.push({ name: 'customer', params: { id: item.userId } }): expand(slot)"
        >
          <template #item.name="{ item }">
            <router-link
              v-if="navigateToCustomer"
              :to="{ name: 'customer', params: { id: item.userId, customerName: item.name } }"
            >
              <span>{{ item.name }}</span>
            </router-link>
            <span v-else>{{ item.name }}</span>
          </template>
          <template #item.userId="{ item }">
            <div class="id-column">
              {{ item.userId }}
            </div>
          </template>
          <template #item.phoneNumber="{ item }">
            <span>{{
              item?.phoneNumber != "" || null
                ? item.phoneNumber
                : $t("common.none")
            }}</span>
          </template>
          <template #item.createdAt="{ item }">
            <span>{{ formatDate(item.createdAt) }}</span>
          </template>
          <template #item.lastLogin="{ item }">
            <span>{{ formatDate(item.lastLogin) }}</span>
          </template>
          <template #item.lastOrderDate="{ item }">
            <span>{{ formatDate(item.lastOrderDate) }}</span>
          </template>
          <template #item.completedOrderCount="{ item }">
            <router-link
              v-if="navigateToCustomer"
              :to="{ name: 'customer', params: { id: item.userId, customerName: item.name } }"
            >
              <span>{{ item.completedOrderCount }}</span>
            </router-link>
            <span v-else>{{ item.completedOrderCount }}</span>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <user-row-details :item="item" />
              <user-latest-order :user-id="item.userId" />
            </td>
          </template>
        </v-data-table>
        <v-row>
          <v-col
            v-if="showSelect"
            class="col-12 col-sm-auto"
          >
            <download-users-button
              :user-data="selectedRows"
              :label="$t('admin.downloadSelectedRows')"
              :disabled="selectedRows.length == 0"
              :page="currentPage+1"
            />
          </v-col>
          <v-col class="col-12 col-sm-auto">
            <download-users-button
              :user-data="data ?? []"
              :label="$t('admin.downloadAllRows')"
              :page="currentPage+1"
            />
          </v-col>
          <v-spacer />
          <v-col class="col-12 col-sm-auto">
            <v-btn
              tile
              color="secondary"
              depressed
              :loading="!!isLoading"
              :disabled="!!isLoading"
              @click="refetchUsers()"
            >
              {{ $t("admin.refreshData") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";
import UserRowDetails from "@/components/tables/UserRowDetails.vue";
import UserMutations from "@/graphql/UserMutations";
import DownloadUsersButton from "@/components/tables/DownloadUsersButton.vue";
import UserLatestOrder from "./UserLatestOrder.vue";

const ORDER_BY = {
  createdAt: "created_at",
  lastLogin: "last_login",
  email: "email",
  name: "name",
  completedOrderCount: "completedOrderCount",
  lastOrderDate: "lastOrderDate",
};

const ITEMS_PER_PAGE_DEFAULT = 10;

export default {
  name: "UsersDataTable",
  components: {
    ApolloErrorAlert,
    UserRowDetails,
    DownloadUsersButton,
    UserLatestOrder,
  },
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    searchString: {
      type: String,
      default: "",
    },
    headerOverrides: {
      type: Array,
      default: () => [],
    },
    hasOrders: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    navigateToCustomer: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    filterLastOrderDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      singleSelect: false,
      selectedRows: [],
      sortString: "",
      sortBy: "",
      itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
      totalCount: 0,
      currentPage: 0,
    };
  },
  computed: {
    customHeaders() {
      return this.headerOverrides.length > 0
        ? this.headerOverrides
        : [
            {
              text: this.$t("userDashboard.userId"),
              align: "start",
              sortable: false,
              value: "userId",
              groupable: false,
              class: "column-header id-column",
            },
            {
              text: this.$t("userDashboard.email"),
              value: "email",
              class: "column-header",
            },
            {
              text: this.$t("userDashboard.name"),
              value: "name",
              class: "column-header",
            },
            {
              text: this.$t("userDashboard.createdAt"),
              value: "createdAt",
              class: "column-header",
            },
            {
              text: this.$t("userDashboard.lastLogin"),
              value: "lastLogin",
              class: "column-header",
            },
            {
              text: this.$t("userDashboard.orders"),
              value: "completedOrderCount",
              class: "column-header",
            },
          ];
    },
    usersQuery() {
      return UserMutations.getUsers;
    },
    filterDates() {
      let dates = {};
      if (this.startDate) {
        dates.startDate = new Date(this.startDate.replaceAll("-", "/"));
      }
      if (this.endDate) {
        dates.endDate = new Date(this.endDate.replaceAll("-", "/"));
      }
      return dates;
    },
    filterQuery() {
      let term = "";
      let fromDate = "*";
      let toDate = "*";
      let dateString = "";

      if (this.searchString && this.searchString?.length > 2) {
        term =
          "(name:*" +
          this.searchString +
          "* OR email:*" +
          this.searchString +
          "* OR user_metadata.phone_number:\"" +
          this.searchString +
          "\")";
      }

      if (!this.filterLastOrderDate) {
        if (this.filterDates?.startDate) {
          fromDate = this.filterDates.startDate.toISOString().split("T")[0];
        }
        if (this.filterDates?.endDate) {
          toDate = this.filterDates.endDate.toISOString().split("T")[0];
        }

        if (fromDate != "*" || toDate != "*") {
          dateString = "created_at:[" + fromDate + " TO " + toDate + "]";
        }
      }

      if (term != "" && dateString != "") {
        return term + " AND " + dateString;
      } else if (term == "" && dateString != "") {
        return dateString;
      } else if (term != "" && dateString == "") {
        return term;
      } else {
        return "";
      }
    },
  },
  watch: {
    searchString() {
      this.currentPage = 0;
    },
    totalCount(newValue) {
      this.$emit("updateTotalRecords", newValue);
    }
  },
  methods: {
    expand(slot) {
      if (!this.expandable) return;
      slot.expand(!slot.isExpanded);
    },
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return publicationDate.toLocaleDateString("en-us", options);
    },
    updateSort(byDesc, event) {
      if (byDesc == "by") {
        if (event != null) {
          this.sortBy = ORDER_BY[event];
          this.sortString = this.sortBy + this.sortDescending(false);
        } else {
          this.sortString = "";
          this.sortBy = "";
        }
      } else if (byDesc == "desc") {
        if (event != null) {
          this.sortString = this.sortBy + this.sortDescending(event);
        }
      }
    },
    customSort(value) {
      return value;
    },
    sortDescending(isDescending) {
      return isDescending ? ":-1" : ":1";
    },
    updateData(data) {
      this.totalCount =
        data.getPaginatedWebServiceUsers?.paginationInfo?.totalCount;
      return data.getPaginatedWebServiceUsers?.collection;
    },
    setPage(isNext) {
      const adjustedNextPage = isNext - 1;
      this.currentPage = adjustedNextPage;
    },
    setItemsPerPage(itemsPerPage) {
      this.currentPage = 0;
      this.itemsPerPage = itemsPerPage;
    },
    refetchUsers() {
      this.currentPage = 0;
      this.$refs.usersQuery.$apollo.queries.query;
    },
  },
};
</script>

<style>
td.text-start > .id-column {
  max-width: 150px;
}
</style>
