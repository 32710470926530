import gql from "graphql-tag";

export default {
  getUserBookshelf: gql`
    query getUserBookshelf {
      books(myBookshelf: true) {
        collection {
          id
          _id
          title
          authors
          thumbnailUrl
          readUrl
        }
      }
    }
  `,

  getBook: gql`
    query getBook($id: ID!) {
      book(id: $id) {
        id
        title
        authors
        thumbnailUrl
      }
    }
  `,
};