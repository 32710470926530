<template>
  <v-container class="pa-0">
    <ApolloQuery
      :query="(gql) => getOrderQuery"
      :variables="{ orderId }"
      :skip="!orderId"
    >
      <template #default="{ result: {data, error}, isLoading }">
        <apollo-error-alert v-if="error" />
        <div v-else-if="isLoading">
          <v-skeleton-loader
            type="card@2"
            width="100%"
            min-height="400px"
          />
        </div>
        <div v-else-if="data">
          <div class="checkout-heading-container d-flex align-center pb-2 mb-5">
            <h1 class="checkout-heading">
              {{ $t("checkout.checkout") }}
            </h1>
            <v-spacer />
            <h2 class="checkout-price">
              <price
                :book-price="Number(data.order.totalPrice)"
                :base-currency="data.order.currency._id"
              />
            </h2>
          </div>
          <div
            v-if="data.order.totalPrice > 0"
            class="checkout-section-container pa-5 mb-5"
          >
            <div class="d-flex align-center justify-space-between">
              <h2 class="checkout-section-header">
                {{ $t('checkout.paymentMethod') }}
              </h2>
              <v-checkbox
                v-if="isAdmin"
                v-model="isAdminOrder"
                :label="$t('checkout.adminOrder')"
                hide-details
                class="mt-0"
              />
            </div>
            <v-radio-group
              v-model="paymentOption"
              class="align-start"
              :row="!isAdmin"
            >
              <div>
                <v-radio
                  :label="$t('checkout.mpesaOrder')"
                  :value="paymentTypes.mpesa"
                />
                <v-img
                  contain
                  max-height="50"
                  max-width="250"
                  :src="mpesaLogo"
                />
              </div>
              <div>
                <v-radio
                  :label="$t('checkout.dpoOrder')"
                  :value="paymentTypes.creditCard"
                />
              </div>
              <div class="mt-3">
                <v-radio
                  v-if="isAdminOrder"
                  :label="$t('checkout.cashOrder')"
                  :value="paymentTypes.cashPayment"
                />
              </div>
            </v-radio-group>
          </div>
          <div class="checkout-section-container pa-5">
            <h2 class="checkout-section-header">
              {{ $t('checkout.form.billingDetails') }}
            </h2>
            <no-cost-order v-if="data.order.totalPrice == 0" />
            <mpesa
              v-else-if="paymentOption == paymentTypes.mpesa"
              :is-admin-order="isAdminOrder"
              :is-print-order="checkOrderHasPrintBooks(data)"
            />
            <credit-card
              v-else-if="paymentOption == paymentTypes.creditCard"
              :is-admin-order="isAdminOrder"
              :is-print-order="checkOrderHasPrintBooks(data)"
            />
            <cash
              v-else-if="paymentOption == paymentTypes.cashPayment"
              :is-print-order="checkOrderHasPrintBooks(data)"
            />
            <IosAudiobookWarning
              class="mt-10"
              :order="data?.order"
            />
          </div>
        </div>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import Mpesa from "./Mpesa";
import currencyMixins from "@/mixins/currencyMixins";
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import Price from "@/components/Price";
import NoCostOrder from "@/components/NoCostOrder";
import CreditCard from "./CreditCard";
import AdminCheckMixin from "@/mixins/AdminCheckMixin";
import { FORMATS } from "@/utils/Const";
import gql from "graphql-tag";
import OrderFragments from "@/graphql/OrderFragments";
import Cash from "./Cash";
import IosAudiobookWarning from "@/components/audioBooks/IosAudiobookWarning";

const mpesaLogoPath = require("@/assets/img/mpesa-logo.png");

const PAYMENT_TYPES = {
  mpesa: "mpesa",
  creditCard: "cc",
  cashPayment: "cash",
};

const ORDER_DETAILS_FRAGMENT = OrderFragments.orderDetailsFragment;

export default {
  name: "CheckoutScreen",
  components: {
    Mpesa,
    ApolloErrorAlert,
    Price,
    NoCostOrder,
    CreditCard,
    Cash,
    IosAudiobookWarning,
  },
  mixins: [
    currencyMixins,
    AdminCheckMixin,
  ],
  data() {
    return {
      mpesaLogo: mpesaLogoPath,
      paymentOption: PAYMENT_TYPES.mpesa,
      isAdminOrder: false,
    };
  },
  computed: {
    currentTabComponent: function () {
      return this.radioGroup;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
    getOrderQuery() {
      return gql`
        query GetOrder($orderId: ID!) {
          order(id: $orderId) {
            ...OrderDetails
          }
        }
        ${ORDER_DETAILS_FRAGMENT}
      `
    },
    paymentTypes() {
      return PAYMENT_TYPES;
    },
  },
  created() {
    if (!this.orderId) {
      this.$router.push({ name: "cart" });
    }
  },
  methods: {
    checkOrderHasPrintBooks(data) {
      const orderLines = data.order.orderLines?.edges.map(({ node }) => node);
      return !!orderLines.find((orderLine) => orderLine.format == FORMATS.print); 
    },
  },
};
</script>

<style scoped>
.checkout-heading-container {
  border-bottom: 1px solid var(--v-lightAccent-base);
}

.checkout-heading-container h1 {
  font-size: 1.5rem;
}

.checkout-section-container {
  background-color: var(--v-lightestAccent-base);
}

.payment-method-radio {
  font-size: 0.875px;
}

.payment-method-radio-active {
  font-weight: 500;
}

.checkout-section-header {
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
