<template>
  <v-container>
    <ApolloQuery
      :query="
        (gql) => gql`
          query getBookById($id: ID!) {
            book(id: $id) {
              id
              _id
              title
              authors
              tagIds
              description
              imageUrl
              description
              price
              currency {
                id
                _id
              }
              modified
              publisher
              language
              thumbnailUrl
              format
            }
          }
        `
      "
      :variables="{
        id: bookId,
      }"
      :update="(data) => data.book"
    >
      <template #default="{ result: { error, data }, isLoading }">
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <v-row v-if="isLoading">
          <v-col class="left-column pa-0">
            <v-skeleton-loader type="image" />
          </v-col>
          <v-col class="book-info-container pa-0 py-sm-2 py-md-4 pl-3 pl-sm-5 pl-md-11 col-md-* col-xs-6">
            <v-skeleton-loader
              type="article"
              style="width: 100%; height: 100%"
            />
          </v-col>
        </v-row>
        <v-row v-if="isLoading">
          <v-col class="left-column details-column pt-xs-5 col-12 col-* pa-0">
            <v-skeleton-loader
              type="article"
              style="width: 100%; height: 100%"
            />
          </v-col>
          <v-col class="pl-sm-5 pl-md-11 pt-sm-0 px-0 pt-7 col-xs-12 col-*">
            <v-row>
              <v-col
                v-for="n in loadingItemsCount"
                :key="n"
                class="col-4 col-md-3 col-lg-2"
              >
                <v-skeleton-loader
                  type="card"
                  style="width: 100%; height: 100%;"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="data && !isLoading">
          <v-row>
            <v-col class="left-column pa-0">
              <v-img
                class="cover-image"
                :src="data.imageUrl"
              >
                <template #placeholder>
                  <image-loading />
                </template>
              </v-img>
            </v-col>
            <v-col class="book-info-container pa-0 py-sm-2 py-md-4 pl-3 pl-sm-5 pl-md-11 col-md-* col-xs-6">
              <login-to-view-modal
                :should-show="!!$route.params.fromEmail"
              />
              <h1>{{ decodeHtml(data.title) }}</h1>
              <div v-if="data.authors && data.authors.length > 0">
                <v-row no-gutters>
                  <h3 class="book-info__author mr-1">
                    {{ $t("bookInfo.by") }}
                  </h3>
                  <h3
                    v-for="(author, index) in data.authors"
                    :key="index"
                    class="book-info__author mr-1"
                  >
                    {{
                      index + 1 == data.authors.length
                        ? author
                        : $t("bookInfo.authorListItem", { author: author })
                    }}
                  </h3>
                </v-row>
              </div>
              <h3
                v-else
                class="book-info__author"
              >
                {{ $t("bookInfo.authorUnknown") }}
              </h3>
              <h2 class="book-info__price my-2 my-sm-3 my-md-5">
                <price
                  :book-price="Number(data.price)"
                  :base-currency="data.currency._id"
                  include-tax
                  :should-round="true"
                />
              </h2>
              <div class="button-container">
                <open-book-button
                  v-if="ownedBook"
                  :read-url="ownedBook.readUrl"
                  :format="data?.format"
                />
                <add-to-library-button
                  v-else-if="data.price == 0"
                  :product-id="data._id"
                  :query-loading="$apollo.queries.books.loading"
                />
                <add-to-cart-button
                  v-else
                  :id="data.id"
                  :simplified-id="data._id"
                  :query-loading="$apollo.queries.books.loading"
                />
                <add-to-book-list-modal
                  v-if="isLoggedIn"
                  :book-id="data._id"
                />
              </div>
              <read-more
                class="d-none d-sm-block"
                style="margin-top: 36px"
                :text="data.description"
              />
            </v-col>
          </v-row>
          <v-row
            class="d-sm-none py-6"
          >
            <read-more :text="data.description" />
          </v-row>
          <v-row
            class="pt-sm-9 pt-0"
          >
            <v-col class="left-column details-column pt-xs-5 col-12 col-* pa-0">
              <book-details :book="data" />
            </v-col>
            <v-col class="pl-sm-5 pl-md-11 pt-sm-0 px-0 pt-7 col-xs-12 col-*">
              <div class="divider-heading mb-3 mb-sm-4">
                <h2>
                  {{ $t("shop.relatedBooks") }}
                </h2>
              </div>
              <related-book-list
                :book-id="$route.params.id"
                :tag-ids="data.tagIds"
                :number-to-show="12"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import RelatedBookList from "@/components/bookList/RelatedBookList";
import ReadMore from "@/components/ReadMore";
import AddToCartButton from "@/views/checkout/AddToCartButton";
import Price from "@/components/Price";
import { decodeHtml } from "@/helpers/generalFunctions";
import BookDetails from "@/components/BookDetails";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import ImageLoading from "@/components/ImageLoading";
import AddToLibraryButton from "@/components/AddToLibraryButton";
import OpenBookButton from "@/components/OpenBookButton";
import BookQueries from "@/graphql/BookQueries";
import AddToBookListModal from "@/components/AddToBookListModal";
import LoginToViewModal from "@/components/LoginToViewModal";

var images = require.context("@/assets/img/", false, /\.jpeg$/);

export default {
  components: {
    AddToCartButton,
    RelatedBookList,
    Price,
    ReadMore,
    BookDetails,
    ApolloErrorAlert,
    ImageLoading,
    AddToLibraryButton,
    OpenBookButton,
    AddToBookListModal,
    LoginToViewModal,
  },
  data() {
    return {
      loadingItemsCount: 12,
      bookTagIds: [],
      ownedBooks: [],
    };
  },
  apollo: {
    books: {
      query: BookQueries.getUserBookshelf,
      result({ data }) {
        this.ownedBooks = data.books.collection;
        return data;
      },
      skip() {
        return !this.isLoggedIn || !this.$auth.user.email_verified || !this.$store.state.auth.getMe;
      },
    },
  },
  computed: {
    bookId() {
      return this.$route.params.id;
    },
    ownedBook() {
      return this.ownedBooks.find((book) => book.id == this.bookId);
    },
    isLoggedIn() {
      return this.$auth.isAuthenticated;
    },
  },
  watch: {
    isLoggedIn(val) {
      if (!val) {
        this.ownedBooks = [];
      }
    },
  },
  methods: {
    imgUrl: function (path) {
      return images("./" + path);
    },
    decodeHtml: function (html) {
      return decodeHtml(html);
    },
  },
};
</script>

<style scoped>
.book-info__price {
  font-size: 22px;
  font-weight: normal;
}

.faded-outline-button {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
}

.book-info__author {
  line-height: 1;
}

.cover-image {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 13px 0px;
}

.button-container {
  display: flex;
  flex-direction: row;
}

.left-column {
  max-width: 250px;
}

.book-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 899px) {
  .left-column {
    width: 230px;
    max-width: 230px;
    align-items: center;
  }
}

@media screen and (max-width: 599px) {
  h1 {
    font-size: 20px;
  }

  .book-info__author {
    font-size: 10px;
  }

  .book-info__price {
    font-size: 16px;
  }

  .button-container {
    flex-direction: column;
  }

  .left-column {
    max-width: 125px;
  }

  .details-column {
    max-width: 100%;
  }
}
</style>
