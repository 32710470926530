<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.75 46.3752C19.75 48.729 18.815 50.9864 17.1506 52.6508C15.4862 54.3152 13.2288 55.2502 10.875 55.2502C8.5212 55.2502 6.26381 54.3152 4.59943 52.6508C2.93504 50.9864 2 48.729 2 46.3752V16.2002"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.5 46.375C37.5 48.7288 36.565 50.9862 34.9006 52.6506C33.2362 54.315 30.9788 55.25 28.625 55.25C26.2712 55.25 24.0138 54.315 22.3494 52.6506C20.685 50.9862 19.75 48.7288 19.75 46.375"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.25 16.2002V46.3752C55.25 48.729 54.315 50.9864 52.6506 52.6508C50.9862 54.3152 48.7288 55.2502 46.375 55.2502C44.0212 55.2502 41.7638 54.3152 40.0994 52.6508C38.435 50.9864 37.5 48.729 37.5 46.3752"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.75 16.2C19.75 18.5538 18.815 20.8112 17.1506 22.4756C15.4862 24.14 13.2288 25.075 10.875 25.075C8.5212 25.075 6.26381 24.14 4.59943 22.4756C2.93504 20.8112 2 18.5538 2 16.2V2"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.5 16.2002C37.5 18.554 36.565 20.8114 34.9006 22.4758C33.2362 24.1402 30.9788 25.0752 28.625 25.0752C26.2712 25.0752 24.0138 24.1402 22.3494 22.4758C20.685 20.8114 19.75 18.554 19.75 16.2002"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.25 2V16.2C55.25 18.5538 54.315 20.8112 52.6506 22.4756C50.9862 24.14 48.7288 25.075 46.375 25.075C44.0212 25.075 41.7638 24.14 40.0994 22.4756C38.435 20.8112 37.5 18.5538 37.5 16.2"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.875 2V17.975"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.625 2V17.975"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M46.375 2V17.975"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.75 46.375V2"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.5 46.375V2"
      :stroke="color"
      stroke-width="3.55"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "BookListIcon",
  props: {
    size: {
      type: Number,
      default: 32,
    },
    color: {
      type: String,
      default: "black",
    },
  },
};
</script>
