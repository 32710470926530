<template>
  <ApolloMutation
    :mutation="(gql) => placeOrderQuery"
    :variables="{
      input: {
        id: orderId,
        phoneNumber: adminOrderDetails?.phoneNumber,
        alternativePhoneNumber: shippingInfo?.altPhoneNumber,
        placeId: shippingInfo?.placeId,
        addressDetails: shippingInfo?.addressDetails,
        schoolId: schoolInfo?.schoolId,
        studentName: schoolInfo?.studentName,
        studentClass: schoolInfo?.studentClass,
        forFullName: adminOrderDetails?.forName,
        forEmail: adminOrderDetails?.forEmail,
        eventId: adminOrderDetails?.eventId,
      },
    }"
    @done="onDone"
    @error="() => { loading = false; }"
  >
    <template #default="{ mutate }">
      <v-btn
        color="primary"
        tile
        :disabled="loading || invalid"
        :loading="loading"
        @click="() => {
          loading = true;
          mutate();
        }"
      >
        {{ $t("checkout.form.placeOrder") }}
      </v-btn>
    </template>
  </ApolloMutation>
</template>

<script>
  import OrderQueries from "@/graphql/OrderQueries";
  import BookQueries from "@/graphql/BookQueries";

  export default ({
    name: "PlaceOrderButton",
    props: {
      invalid: {
        type: Boolean,
        default: false,
      },
      shippingInfo: {
        type: Object,
        default: null,
      },
      schoolInfo: {
        type: Object,
        default: null,
      },
      isPickupOrder: {
        type: Boolean,
        default: false,
      },
      adminOrderDetails: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        orderId: this.$store.state.cart.currentCart,
        loading: false,
      };
    },
    computed: {
      placeOrderQuery() {
        return OrderQueries.placeCashOrder;
      },
    },
    methods: {
      async onDone({ data }) {
        if (data.placeCashOrder.order.confirmed) {
          await this.$apollo.query({
            query: BookQueries.getUserBookshelf,
            fetchPolicy: "network-only",
          });
          this.$store.commit("setCurrentCart", null);
          this.loading = false;
          this.$router.push({
            name: "confirmed",
            params: {
              id: this.orderId,
            },
          });
        }
      },
    },
  });
</script>