var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('ApolloQuery',{ref:_vm.queryRef,attrs:{"query":(gql) => _vm.eventsQuery,"variables":{
      searchString: _vm.searchString,
      startDate: _vm.filterDates.startDate,
      endDate: _vm.filterDates.endDate,
      orderBy: _vm.orderBy,
      itemsPerPage: _vm.itemsPerPage,
      nextCursor: _vm.nextCursor,
      prevCursor: _vm.prevCursor
    },"update":(data) => _vm.updateData(data, 'events')},scopedSlots:_vm._u([{key:"default",fn:function({ result: { error, data: events }, isLoading }){return [(error)?_c('apollo-error-alert'):_vm._e(),_c('v-data-table',{staticClass:"data-table-container mb-6",attrs:{"items":events ?? [],"item-key":"id","headers":_vm.customHeaders,"disable-filtering":"","loading":!!isLoading,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"server-items-length":_vm.totalCount,"items-per-page":_vm.itemsPerPage,"footer-props":{
          'items-per-page-options': _vm.defaultPaginationOptions,
          'items-per-page-text': _vm.$t('transactionReports.itemsPerPage'),
        },"loading-text":_vm.$t('transactionReports.loading'),"no-data-text":_vm.$t('transactionReports.noData')},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sort-by":function($event){return _vm.updateSort('by', $event)},"update:sort-desc":function($event){return _vm.updateSort('desc', $event)},"update:page":function($event){return _vm.setCursor(_vm.pageData, $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'eventOverview', params: { id: item._id, eventName: item?.name ?? null } }}},[_c('span',[_vm._v(_vm._s(item._id))])])]}},{key:"item.startDate",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.startDate)))])]}},{key:"item.endDate",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.endDate)))])]}},{key:"item.taxRate.id",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.$t('countryCodes.'+item.taxRate._id)))])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"col-12 col-sm-auto"},[_c('v-btn',{attrs:{"tile":"","color":"secondary","depressed":"","loading":!!isLoading,"disabled":!!isLoading},on:{"click":function($event){return _vm.refetchQuery(_vm.queryRef)}}},[_vm._v(" "+_vm._s(_vm.$t("transactionReports.refreshTransactions"))+" ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }