<template>
  <div>
    <p class="readmore-text">
      <span v-if="!readMoreActivated && truncated">{{ $t('bookInfo.truncatedText', {text: decodeHtml(truncated)}) }}</span>
      <span v-else>{{ decodeHtml(text) }}</span>
    </p>
    <v-spacer />
    <a
      v-if="!readMoreActivated && truncated"
      class="readmore-link"
      href="#"
      @click="activateReadMore"
    >
      {{ $t("bookInfo.readMore") }}
      <v-icon
        color="primary"
        small
      >mdi-arrow-right</v-icon>
    </a>
  </div>
</template>

<script>
import { truncateText } from "@/utils/GeneralFunctions";
import { decodeHtml } from "@/helpers/generalFunctions";

const READ_MORE_THRESHOLD_MOBILE = 40;
const READ_MORE_THRESHOLD_DESKTOP = 60;

export default {
  name: "ReadMore",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      readMoreActivated: false,
    };
  },
  computed: {
    truncateThreshold() {
      return this.$vuetify.breakpoint.smAndDown ? READ_MORE_THRESHOLD_MOBILE : READ_MORE_THRESHOLD_DESKTOP;
    },
    truncated() {
      return truncateText(this.text, this.truncateThreshold);
    },
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
    decodeHtml(html) {
      return decodeHtml(html);
    },
  },
};
</script>

<style scoped>
.readmore-text {
  margin-bottom: 5px;
  font-size: 16px;
}

.readmore-link {
  text-decoration: none;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .readmore-text, .readmore-link {
    font-size: 12px;
  }
}
</style>
