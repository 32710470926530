var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"previous-orders-container mt-3 mt-sm-5 mt-md-6"},[_c('div',{staticClass:"order-header-container d-flex justify-space-between align-center pb-3 mb-6"},[_c('h1',{staticClass:"previous-orders-header"},[_vm._v(" "+_vm._s(_vm.$t("profile.previousPurchases"))+" ")])]),_c('ApolloQuery',{attrs:{"query":(gql) => gql`
      query {
        getMyOrders(confirmed: true) {
          id
          _id
          updatedAt
          orderFulfillment {
            id
            fulfilledAt
          }
        }
      }
    `,"update":(data) => data.getMyOrders},scopedSlots:_vm._u([{key:"default",fn:function({ result: { error, data }, isLoading }){return [(error)?_c('div',[_c('apollo-error-alert',{attrs:{"error":error}})],1):(isLoading)?_c('v-container',_vm._l((_vm.loadingCount),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"my-3",attrs:{"type":"text"}})}),1):(data && data.length > 0)?_c('div',_vm._l((data),function(order){return _c('div',{key:order.id,staticClass:"my-2"},[_c('div',{staticClass:"order-number"},[_vm._v(" "+_vm._s(_vm.$t("checkout.orderNumber", { number: order._id }))+" ")]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"order-date"},[_vm._v(" "+_vm._s(_vm.formatDate(order.updatedAt))+" ")]),_c('div',[(order?.orderFulfillment)?_c('v-chip',{staticClass:"mr-3",attrs:{"label":"","color":order.orderFulfillment.fulfilledAt ? 'success' : 'secondary'}},[_c('v-icon',{staticClass:"mb-1 mr-3"},[_vm._v(" mdi-book-open-page-variant ")]),_vm._v(" "+_vm._s(order.orderFulfillment?.fulfilledAt ? _vm.$t("checkout.orderComplete.deliveryComplete", { date: _vm.formatDate(order?.orderFulfillment?.fulfilledAt) }) : _vm.$t("checkout.orderComplete.deliveryPending"))+" ")],1):_vm._e(),_c('router-link',{staticClass:"view-order-link",attrs:{"to":{ name: 'confirmed', params: {id: order.id} }}},[_vm._v(" "+_vm._s(_vm.$t('common.view'))+" ")])],1)])])}),0):_c('h2',[_vm._v(" "+_vm._s(_vm.$t('profile.noOrders'))+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }