<template>
  <div>
    <v-container class="pa-0">
      <ApolloMutation
        :mutation="(gql) => placeFreeOrderMutation"
        :variables="{
          orderId: orderId,
        }"
        :refetch-queries="()=> [{
          query: getBookshelfQuery
        }]"
        @done="onDone"
      >
        <template #default="{mutate, loading}">
          <p>{{ $t('checkout.freeOrderText') }}</p>
          <v-btn
            tile
            depressed
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="mutate()"
          >
            {{ $t("checkout.placeFreeOrder") }}
          </v-btn>
        </template>
      </ApolloMutation>
    </v-container>
  </div>
</template>

<script>
import OrderQueries from "@/graphql/OrderQueries";
import BookQueries from "@/graphql/BookQueries";

export default {
  name: "NoCostOrder",
  computed: {
    placeFreeOrderMutation() {
      return OrderQueries.placeFreeOrder;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
    getBookshelfQuery() {
      return BookQueries.getUserBookshelf;
    },
  },
  methods: {
      onDone (data) {
        let id = data.data.placeFreeOrder.order.id;
        this.$store.commit("setCurrentCart", null);
        if (id) {
          this.$router.push({
            name: "confirmed",
            params: {
              id: id,
            },
          });
        }
      },
  },
};
</script>
