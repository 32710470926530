<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form v-model="valid">
        <v-container class="pa-0">
          <v-row
            v-if="!$apollo.queries.getWebServiceUser.loading"
            class="mb-2"
          >
            <v-col class="col-12 col-md-6">
              <admin-order
                class="mt-3"
                :is-cash-transaction="true"
                @updateAdminOrderDetails="setAdminOrderDetails"
              />
              <delivery-options
                v-if="isPrintOrder"
                @updateShippingInfo="setShippingInfo"
                @updateSchoolInfo="setSchoolInfo"
                @updateIsPickupOrder="setIsPickupOrder"
                @updateIsValid="setDeliveryOptionsValid"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <place-order-button
            :invalid="invalid || !deliveryOptionsValid"
            :shipping-info="shippingInfo"
            :school-info="schoolInfo"
            :is-pickup-order="isPickupOrder"
            :is-admin-order="true"
            :admin-order-details="adminOrderDetails"
          />
        </v-container>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import PlaceOrderButton from "@/views/checkout/CashPlaceOrderButton";
import { required, max, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import i18n from "@/i18n";
import DeliveryOptions from "@/views/checkout/print/DeliveryOptions";
import gql from "graphql-tag";
import AdminOrder from "@/views/checkout/AdminOrder";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: i18n.t("errors.required", { field: "{_field_}" }),
});

extend("max", {
  ...max,
  message: i18n.t("errors.maxLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("min", {
  ...min,
  message: i18n.t("errors.minLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

export default {
  name: "CashScreen",
  components: {
    PlaceOrderButton,
    ValidationObserver,
    DeliveryOptions,
    AdminOrder,
  },
  props: {
    isPrintOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      shippingInfo: {
        altPhoneNumber: null,
        addressDetails: null,
        placeId: null,
        eventId: null,
      },
      schoolInfo: {
        schoolId: null,
        studentName: null,
        studentClass: null,
      },
      isPickupOrder: false,
      deliveryOptionsValid: this.isPrintOrder ? false : true,
      adminOrderDetails: {
        forEmail: null,
        forName: null,
        phoneNumber: null,
        event: null,
      },
    };
  },
  apollo: {
    getWebServiceUser: {
      query: gql`
        query getUserInfo {
          getWebServiceUser {
            id
            email
            familyName
            givenName
            phoneNumber
            emailVerified
            alternativePhoneNumber
          }
        }
      `,
    },
  },
  methods: {
    setShippingInfo(shippingInfo) {
      this.shippingInfo = shippingInfo;
    },
    setSchoolInfo(schoolInfo) {
      this.schoolInfo = schoolInfo;
    },
    setIsPickupOrder(isPickupOrder) {
      this.isPickupOrder = isPickupOrder;
    },
    setDeliveryOptionsValid(isValid) {
      this.deliveryOptionsValid = isValid;
    },
    setAdminOrderDetails(adminOrderDetails) {
      this.adminOrderDetails = adminOrderDetails;
    },
  },
};
</script>
