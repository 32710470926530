<template>
  <v-dialog
    v-model="dialog"
    class="filter-dialog-container"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        tile
        class="outline-button"
        v-bind="attrs"
        :small="small"
        outlined
        :disabled="disabled"
        v-on="on"
      >
        {{ $t("filter.filter") }}
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card
      tile
      class="fill-height filter-dialog"
    >
      <v-card-title
        class="filter-header-container has-box-shadow pa-3"
        align="center"
      >
        <v-row
          no-gutters
          class="d-flex flex-row"
        >
          <v-col
            class="d-flex"
            cols="12"
            sm="auto"
          >
            <h3 class="filter-header">
              {{ $t("filter.filterBooks") }}
            </h3>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="auto"
          >
            <h3
              v-if="viewAll"
              class="filter-header"
            >
              <span class="ml-0 mr-2 mx-sm-2">/</span>
              <span class="view-all-header">{{ $t('filter.categories') }}</span>
            </h3>              
          </v-col>
        </v-row>
        <v-spacer />
        <v-btn
          icon
          @click="!viewAll ? toggleDialog() : viewAllToggle()"
        >
          <v-icon :color="!viewAll ? 'primaryText' : 'primary'">
            {{ !viewAll ? "mdi-close" : "mdi-chevron-left" }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="fill-height filter-card-body pa-3">
        <v-container
          fluid
          class="pa-0 pb-3"
        >
          <v-row
            class="header-border py-3 mb-3"
            no-gutters
            align="center"
          >
            <h3 class="filter-header">
              {{ $t("filter.categories") }}
            </h3>
            <v-spacer />
            <v-col
              cols="12"
              sm="8"
            >
              <v-row
                no-gutters
                justify="end"
                align="center"
              >
                <tag-search-bar
                  id="tag-search-bar"
                  v-model="searchTerm"
                  class="my-3"
                  :placeholder="$t('common.search')"
                />
                <v-btn
                  v-if="!viewAll"
                  :block="$vuetify.breakpoint.xs"
                  tile
                  class="outline-button ml-sm-3"
                  outlined
                  :small="$vuetify.breakpoint.xs"
                  height="38px"
                  @click="viewAllToggle"
                >
                  {{ $t('filter.viewAll') }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <div v-if="!searchEnabled && viewAll">
            <v-tabs
              v-model="selectedTab"
              class="filter-tab-container"
              no-gutters
              background-color="lightestAccent"
              show-arrows
            >
              <v-tab
                v-for="letter in alphabet"
                :key="letter"
                active-class="filter-tab-active"
                class="filter-tab"
              >
                {{ letter }}
              </v-tab>
              <v-tab
                active-class="filter-tab-active"
                class="filter-tab"
              >
                {{ $t('filter.symbol') }}
              </v-tab>
            </v-tabs>
          </div>
          <tag-display
            v-model="selectedTagIds"
            class="pb-3"
            :tags="
              searchEnabled ? filteredTagsBySearch :
              viewAll ? filteredTagsByLetter :
              featuredCategoryTags
            "
          />
          <v-expansion-panels
            v-if="!viewAll && !searchEnabled"
            :value="0"
          >
            <v-expansion-panel class="filter-expansion-panel">
              <v-expansion-panel-header class="header-border pa-0">
                <h3 class="filter-header">
                  {{ $t("filter.gradeLevel") }}
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0">
                <tag-display
                  v-model="selectedTagIds"
                  class="pb-3"
                  :tags="gradeLevelTags"
                />                  
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-text>
      <div class="has-box-shadow">
        <v-divider />
        <v-card-actions>
          <v-btn
            tile
            outlined
            text
            class="outline-button has-box-shadow"
            :small="$vuetify.breakpoint.xs"
            @click="toggleDialog"
          >
            {{ $t("button.cancel") }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { FEATURED_TAGS, GRADE_LEVEL_TAGS } from "@/utils/Const";
import TagDisplay from "@/components/TagDisplay";
import TagSearchBar from './TagSearchBar';
import gql from 'graphql-tag';

export default {
  components: {
    TagDisplay,
    TagSearchBar,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      searchTerm: '',
      selectedTagIds: this.value,
      alphabet: 'abcdefghijklmnopqrstuvwxyz',
      selectedTab: null,
      viewAll: false,
    };
  },
  apollo: {
    tags: {
      query: gql`
        query {
          tags {
            id
            _id
            name
            parentId
          }
        }
      `,
      skip() {
        return !this.dialog;
      },
    }
  },
  computed: {
    filteredTagsBySearch() {
      let filteredTags = [];
      let searchTerm = this.searchTerm.toLowerCase();

      if (searchTerm != '' && searchTerm.length >= 3 && this.tags) {
        filteredTags = this.tags.filter((tag) => tag.name.toLowerCase().includes(searchTerm));
      }

      return filteredTags;
    },
    tagsSelected() {
      let tagsSelected = [];
      if (this.selectedTagIds.length > 0 && this.tags) {
        tagsSelected = this.tags.filter((tag) => this.selectedTagIds.includes(tag._id));
      }
      return tagsSelected;
    },
    filteredTagsByLetter() {
      let filteredTags = [];
      let searchTerm = this.alphabet[this.selectedTab];

      if (searchTerm != null && this.tags) {
        filteredTags = this.tags.filter((tag) => tag.name.toLowerCase()[0] == searchTerm);
      } else if (this.tags) {
        filteredTags = this.tags.filter((tag) => !this.alphabet.includes(tag.name.toLowerCase()[0]));
      }

      if (filteredTags.length > 0) {
        filteredTags = filteredTags.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
      }

      return filteredTags;
    },
    featuredCategoryTags() {
      return FEATURED_TAGS;
    },
    gradeLevelTags() {
      return GRADE_LEVEL_TAGS; 
    },
    searchEnabled() {
      return this.searchTerm.length >= 3;
    }
  },
  methods: {
    viewAllToggle() {
      this.viewAll = !this.viewAll;
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
.filter-dialog {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.filter-header-container {
  color: var(--v-primaryText-base);
  background-color: var(--v-background-base);
}

.has-box-shadow {
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.filter-header-container .filter-header {
  text-transform: uppercase;
  font-weight: 500;
}

.view-all-header {
  color: var(--v-primary-base);
}

.filter-header {
  color: var(--v-primaryText-base);
  font-size: 1.5rem;
  font-weight: normal;
}

:deep(.v-dialog.v-dialog--active) {
  max-width: 1100px;
  height: 600px;
  border-radius: 0px;
  margin: 12px;
}

.view-all-btn {
  border: 1px solid var(--v-lightAccent-base);
}

.filter-card-body {
  display: flex;
  flex-grow: 1;
  background-color: var(--v-lightestAccent-base);
  overflow-y: scroll;
}

.filter-tab {
  padding: 5px;
  margin: 0px;
  min-width: 0px;
  color: var(--v-primaryText-base) !important;
  border: 1px solid var(--v-lightestAccent-base);
  transition: border-color 3s, background-color 0.3s color 0.3s;
}

.filter-tab-active {
  color: var(--v-primary-base) !important;
  background-color: var(--v-background-base);
  border: 1px solid var(--v-primary-base);
}

.filter-expansion-panel {
  background-color: transparent !important;
  border-radius: 0px !important;
}

@media screen and (max-width: 899px) {
  .filter-list-header {
    background-color: var(--v-background-base);
  }

  .filter-header {
    font-size: 1rem;
  }

  :deep(.v-dialog.v-dialog--active) {
    max-width: 1100px;
    height: 90%;
    border-radius: 0px;
  }
}
</style>
