<template>
  <v-container class="pa-0">
    <ApolloQuery
      ref="transactionReports"
      :query="(gql) => transactionQuery"
      :variables="{
        confirmed: confirmed,
        startDate: filterDates.startDate,
        endDate: filterDates.endDate,
        orderBy: orderBy,
        itemsPerPage: itemsPerPage,
        nextCursor: nextCursor,
        prevCursor: prevCursor,
      }"
      :update="updateData"
    >
      <template #default="{ result: { error, data: orderLines }, isLoading }">
        <apollo-error-alert v-if="error" />
        <v-data-table
          v-model="selectedRows"
          :items="orderLines ? orderLines : []"
          item-key="id"
          :headers="customHeaders"
          class="data-table-container mb-6"
          disable-filtering
          :custom-sort="customSort"
          :loading="!!isLoading"
          :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
          :server-items-length="totalCount"
          :items-per-page.sync="itemsPerPage"
          show-select
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': $t('transactionReports.itemsPerPage'),
          }"
          :loading-text="$t('transactionReports.loading')"
          :no-data-text="$t('transactionReports.noData')"
          @update:sort-by="updateSort('by', $event)"
          @update:sort-desc="updateSort('desc', $event)"
          @update:page="setCursor(pageData, $event)"
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        >
          <template #item.forOrder.updatedAt="{ item }">
            <span>{{ formatDate(item.forOrder.updatedAt) }}</span>
          </template>
          <template #item.format="{ item }">
            <span>{{ getFormat(item?.format) }}</span>
          </template>
          <template #item.forOrder.id="{ item }">
            <span>{{ item.forOrder._id }}</span>
          </template>
          <template #item.forOrder.customerEmail="{ item }">
            <span>{{ item.forOrder.customerEmail ?? item.forOrder.customerId ?? null }}</span>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <transaction-report-details :item="item" />
            </td>
          </template>
          <template #item.actions="{ item }">
            <v-menu
              top
              left
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :disabled="!item.forOrder?.orderFulfillment"
                  @click="openOptionsDialog(item)"
                >
                  <v-list-item-title>{{ $t('transactionReports.updateFulfillmentStatus') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-row>
          <v-col
            class="col-12 col-sm-auto"
          >
            <download-transaction-reports-button
              :transaction-data="selectedRows"
              :label="$t('admin.downloadSelectedReports')"
              :disabled="selectedRows.length == 0"
              :page="currentPage"
            />
          </v-col>
          <v-col
            class="col-12 col-sm-auto"
          >
            <download-transaction-reports-button
              :transaction-data="orderLines ?? []"
              :label="$t('admin.downloadPage')"
            />
          </v-col>
          <v-spacer />
          <v-col
            class="col-12 col-sm-auto"
          >
            <v-btn
              tile
              color="secondary"
              depressed
              :loading="!!isLoading"
              :disabled="!!isLoading"
              @click="refetchTransactions()"
            >
              {{ $t('transactionReports.refreshTransactions') }}
            </v-btn>
          </v-col>
        </v-row>
        <update-order-fulfillment-modal
          :id="selectedFulfilmentId"
          v-model="showUpdateFulfillmentModals"
        />
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import ApolloErrorAlert from '@/components/ApolloErrorAlert.vue';
import DownloadTransactionReportsButton from '@/components/DownloadTransactionReportsButton.vue';
import TransactionReportDetails from '@/components/TransactionReportDetails.vue';
import UpdateOrderFulfillmentModal from '../modals/UpdateOrderFulfillmentModal.vue';
import gql from 'graphql-tag';

const ORDER_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const DEFAULT_ITEMS_PER_PAGE = 10;

export default {
  name: "TransactionsDataTable",
  components: {
    ApolloErrorAlert,
    DownloadTransactionReportsButton,
    TransactionReportDetails,
    UpdateOrderFulfillmentModal,
  },
  props: {
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    confirmed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      singleSelect: false,
      customHeaders: [
        {
          text: this.$t('transactionReports.transactionId'),
          align: "start",
          sortable: false,
          value: "_id",
          groupable: false,
          class: "column-header",
        },
        { text: this.$t('transactionReports.orderId'), value: "forOrder.id", class: "column-header" },
        { text: this.$t('transactionReports.date'), value: "forOrder.updatedAt", class: "column-header" },
        { text: this.$t('transactionReports.bookId'), value: "productId", class: "column-header" },
        { text: this.$t('transactionReports.name'), value: "name", class: "column-header" },
        { text: this.$t('transactionReports.customerId'), value: "forOrder.customerEmail", class: "column-header" },
        { text: this.$t('transactionReports.price'), value: "priceSet", class: "column-header" },
        { text: this.$t('transactionReports.currency'), value: "forOrder.currency._id", sortable: false, class: "column-header" },
        { text: this.$t('bookInfo.format'), value: "format", class: "column-header"},
        { text: null, value: 'actions', sortable: false },
        { value: 'data-table-expand' },
      ],
      selectedRows: [],
      orderBy: {
        forOrder_updatedAt: "DESC",
        forOrder_id: "DESC"
      },
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      nextCursor: null,
      prevCursor: null,
      totalCount: 0,
      pageData: {},
      currentPage: 1,
      showUpdateFulfillmentModals: false,
      selectedFulfilmentId: null,
    };
  },
  computed: {
    transactionQuery() {
      return gql`
        query getOrderLines(
          $confirmed: Boolean,
          $startDate: String,
          $endDate: String,
          $orderBy: OrderLineFilter_order,
          $itemsPerPage: Int,
          $nextCursor: String,
          $prevCursor: String,
        ) {
          orderLines(
            forOrder_confirmed: $confirmed,
            forOrder_updatedAt: {after: $startDate, before: $endDate},
            order: [$orderBy],
            first: $itemsPerPage,
            after: $nextCursor,
            before: $prevCursor,
          ) {
            totalCount
            pageInfo {
              endCursor
              startCursor
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                _id
                name
                publisher
                productId
                basePrice
                baseCurrency {
                  id
                  _id
                }
                preTaxPriceSet
                priceSet
                discountSet
                quantity
                format
                forOrder {
                  id
                  _id
                  customerId
                  customerEmail
                  confirmed
                  customerLocale
                  updatedAt
                  currency {
                    id
                    _id
                  }
                  orderCoupon {
                    id
                    code
                  }
                  taxRate {
                    id
                    _id
                    rate
                  }
                  safaricomMpesaRequests {
                    id
                    merchantRequestId
                    resultCode
                  }
                  dpoPayments {
                    id
                    transactionReference
                    resultCode
                  }
                  cashPayments {
                    id
                    succeededAt
                  }
                  adminOrder {
                    id
                    _id
                    forUserFullName
                    forUserEmail
                    forUserPhone
                    adminUserId
                    adminEmail
                    adminFullName
                    claimedAt
                    createdAt
                    updatedAt
                  }
                  shippingFeeSet
                  orderFulfillment {
                    id
                    fulfilledAt
                    addressLine1
                    addressLine2
                    addressPostalCode
                    phoneNumber
                    altPhoneNumber
                    school {
                      id
                      name
                    }
                    studentName
                    studentClass
                    notes
                    createdAt
                  }
                  event {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `;
    },
    filterDates() {
      let dates = {};
      if (this.startDate) {
        dates.startDate = new Date(this.startDate.replaceAll("-", "/"));
      }
      if (this.endDate) {
        dates.endDate = new Date(this.endDate.replaceAll("-", "/"));
        dates.endDate.setDate(dates.endDate.getDate() + 1);
      }
      return dates;
    },
  },
  methods: {
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
      return publicationDate.toLocaleDateString('en-us', options);
    },
    updateSort(byDesc, event){
      this.orderBy = {};
      if (byDesc == 'by') {
        this.sortBy = null;
        if (event != null) {
          let orderByString = event.replace(".", "_");
          this.sortBy = orderByString;
          this.orderBy[this.sortBy] = this.sortDescending(false);
        }
      }
      else if (byDesc == 'desc') {
        if (event != null) {
          this.orderBy[this.sortBy] = this.sortDescending(event);
        }
      }
    },
    customSort(value) {
      return value;
    },
    sortDescending(isDescending) {
      return isDescending ? ORDER_DIRECTIONS.DESC : ORDER_DIRECTIONS.ASC;
    },
    updateData(data) {
      this.totalCount = data.orderLines.totalCount;
      this.pageData = data.orderLines.pageInfo;
      return data.orderLines.edges.map((edge) => edge.node);
    },
    setCursor(newCursor, isNext) {
      if (isNext > this.currentPage && this.nextCursor != newCursor.endCursor) {
        this.currentPage++;
        this.nextCursor = newCursor.endCursor;
        this.prevCursor = null;
      } else if (isNext < this.currentPage && this.prevCursor != newCursor.startCursor) {
        this.currentPage--;
        this.nextCursor = null;
        this.prevCursor = newCursor.startCursor;
      }
    },
    refetchTransactions() {
      this.$refs.transactionReports.$apollo.queries.query.refetch();
    },
    getFormat(format) {
      if (format) {
        const formatText = format.replace("application/", "");
        const translationKey = `formats.${formatText}`;
        return this.$te(translationKey) ? this.$t(translationKey) : formatText;        
      }

      return this.$t('common.none');
    },
    openOptionsDialog(item) {
      this.selectedFulfilmentId = item?.forOrder?.orderFulfillment?.id;
      this.showUpdateFulfillmentModals = true;
    },
  },
}
</script>