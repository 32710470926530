<template>
  <!-- TODO: Changed to currently take in only the first tag Id as a filter,
      as having more than one while tag filtering is additive almost always results in the same 12 first books being returned.
      This should be changed once the tag system has been updated
      Github Issue: https://github.com/ekitabu-LLC/shop-web/issues/174
  -->
  <ApolloQuery
    :query="(gql) => relatedBooksQuery"
    :variables="{
      tags: firstTagId,
    }"
    :update="(data) => {
      relatedBooks = data.books.collection;
    }"
  >
    <template #default="{ result: { error }, isLoading }">
      <apollo-error-alert
        v-if="error"
        :error="error"
      />
      <v-row v-else-if="isLoading">
        <v-col
          v-for="n in numberToShow"
          :key="n"
          class="col-4 col-md-3 col-lg-2 pa-1 pa-sm-2 pa-md-3"
        >
          <v-skeleton-loader
            type="card"
          />
        </v-col>
      </v-row>
      <v-row
        v-else-if="filteredBooks.length > 0"
        no-gutters
        class="mx-n1 mx-sm-n2 mx-md-n3"
      >
        <book-list-item
          v-for="book in filteredBooks"
          :id="book.id"
          :key="book.id"
          :price="book.price"
          :thumbnail-url="book.thumbnailUrl"
          :title="book.title"
          :currency="book.currency"
          column-classes="col-4 col-md-3 col-lg-2 pa-1 px-sm-2 px-md-3"
          :is-owned="checkInBookshelf(book.id) != null"
        />
      </v-row>
    </template>
  </ApolloQuery>
</template>

<script>
import ApolloErrorAlert from '@/components/ApolloErrorAlert.vue';
import BookListItem from "@/components/bookList/BookListItem";
import BookshelfMixin from "@/mixins/BookshelfMixin";
import BookFragments from '@/graphql/BookFragments';
import gql from 'graphql-tag';

const BOOK_DETAILS_FRAGMENT = BookFragments.bookDetailsFragment;

export default {
  components: {
    BookListItem,
    ApolloErrorAlert,
  },
  mixins: [BookshelfMixin],
  props: {
    tagIds: {
      type: Array,
      default: () => [],
    },
    numberToShow: {
      type: Number,
      default: 25,
    },
    bookId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      relatedBooks: [],
    };
  },
  computed: {
    relatedBooksQuery() {
      return gql`
        query getFilteredBooks($tags: [Int]){
          books(tags_list: $tags, page: 1) {
            collection {
              ...BookDetails            
            }
          }
        }
        ${BOOK_DETAILS_FRAGMENT}
      `;
    },
    filteredBooks() {
      return this.relatedBooks.filter((book) => book.id !== this.bookId).slice(0, this.numberToShow);
    },
    firstTagId() {
      return [this.tagIds?.find((tag) => tag)];
    },
  },
};
</script>
