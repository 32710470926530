import BasketIcon from "@/components/icons/BasketIcon.vue";
import AudiobookIcon from "@/components/icons/AudiobookIcon.vue";

export const icons = {
  values: {
    basket: {
      component: BasketIcon,
    },
    audiobook: {
      component: AudiobookIcon,
    },
  },
}