const defaultCurrency = "KES";

import OrderQueries from "@/graphql/OrderQueries";
import gql from "graphql-tag";
export default {
  data() {
    return {
      loading: false,
    };
  },
  apollo: {
    currencies: {
      query: gql`
        query {
          currencies {
            edges {
              node {
                id
                _id
              }
            }
          }
        }
      `,
      update: (data) => data.currencies.edges.map((edge) => edge.node._id),
    },
  },
  methods: {
    getPriceString(currencyString, price, shouldRound) {
      const shouldRoundToInt = ["KES", "MWK", "RWF"].includes(currencyString);
      const precision = shouldRoundToInt ? 0 : 2;

      if (shouldRound && shouldRoundToInt) {
        price = price.toFixed(precision);
      }
      else {
        price = price.toFixed(precision);
      }
      
      const formatter = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currencyString,
        maximumFractionDigits: precision,
      }); 

      return formatter.format(price);
    },
    async getPrice(baseCurrencyCode, basePrice) {
      let selectedCurrencyCode = this.getCurrencyCode();

      if (baseCurrencyCode.toLowerCase() == selectedCurrencyCode.toLowerCase()) {
        return basePrice;
      }

      this.loading = true;
      let response = await this.$apollo
      .query({
        query: OrderQueries.convertPrice,
        variables: {
          fromCurrency: baseCurrencyCode,
          toCurrency: selectedCurrencyCode,
        },
      });
      this.loading = false;

      let rate = response.data.getCurrencyConversionRate.rate;
      let convertedPrice = basePrice * rate;
      return convertedPrice;
    },
    getCurrencyCodes() {
      return this.currencies;
    },
    getDefaultCurrencyCode() {
      return defaultCurrency;
    },
    getCurrencyCode() {
      if (
        this.$store.state &&
        this.$store.state.currency.currencyCode &&
        typeof this.$store.state.currency.currencyCode == "string" &&
        this.$store.state.currency.currencyCode != ""
      ) {
        return this.$store.state.currency.currencyCode;
      }
      return this.getDefaultCurrencyCode();
    },
  },
};
