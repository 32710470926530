<template>
  <v-alert
    v-if="isIosOrMac && hasAudiobooks"
    class="mt-3 mx-auto text-center"
    color="accent"
    style="width: fit-content;"
  >
    {{ $t("checkout.delivery.hasIosAndAudio") }}
  </v-alert>
</template>

<script>
import { isIosOrMac } from "@/helpers/devices";
import { FORMATS } from "@/utils/Const";

export default {
  name: "IosAudiobookWarning",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isIosOrMac,
    hasAudiobooks() {
      const orderLines = this.order.orderLines?.edges?.map(({ node }) => node);
      return !!orderLines.find(
        (orderLine) => orderLine.format == FORMATS.audio
      );
    },
  },
};
</script>
