<template>
  <v-container class="pa-0">
    <div class="header-border pb-3 mb-3">
      <v-row
        no-gutters
        align="center"
      >
        <h1 class="book-list-header">
          {{ publisherName }}
        </h1>
        <v-spacer />
        <v-btn
          tile
          outlined
          elevation="2"
          class="outline-button"
        >
          {{ $t('aToZ') }}
          <v-icon
            class="ml-3"
            small
          >
            mdi-arrow-up
          </v-icon>
        </v-btn>
      </v-row>
    </div>
    <div>
      <ApolloQuery
        :query="(gql) => getBooksByPublisher"
        :variables="{
          page: 1,
        }"
        :update="(data) => data.books.collection"
      >
        <template #default="{ result: { error, data }, isLoading, query }">
          <book-display
            :data="data"
            :is-loading="!!isLoading"
            :error="error"
            :query="query"
            :loading-items="loadingItemsCount"
          />
          <v-container v-if="(!data || data.length === 0) && !isLoading">
            <v-row justify="center">
              <v-col
                class="col-12 col-sm-8 pa-16"
                justify="center"
                align="center"
              >
                <book-list-icon :size="72" />
                <h1>{{ $t('shop.noBooksForPublisher') }}</h1>
                <v-btn
                  tile
                  block
                  color="primary"
                  class="mt-4"
                  :to="{ name: 'home' }"
                >
                  {{ $t('common.returnToShop') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </ApolloQuery>
    </div>
  </v-container>
</template>

<script>
import BookListIcon from "@/components/icons/BookListIcon";
import BookFragments from "@/graphql/BookFragments";
import BookDisplay from "@/components/BookDisplay";
import gql from "graphql-tag";

const LOADING_ITEM_COUNT = 12;

const BOOK_DETAILS_FRAGMENT = BookFragments.bookDetailsFragment;

// TODO: Needs to be hooked up to a proper publisher query once that is implemented in the backend
// Github Issue: https://github.com/ekitabu-LLC/shop-web/issues/176

export default {
  name: "PublisherBookList",
  components: {
    BookListIcon,
    BookDisplay,
  },
  props: {
    publisherName: {
      type: String,
      default: "PUBLISHER NAME HERE",
    },
  },
  data() {
    return {
      loadingItemsCount: LOADING_ITEM_COUNT,
    };
  },
  computed: {
    publisherId() {
      return this.$route.params.id;
    },
    getBooksByPublisher() {
      return gql`query getPublisherBooks($page: Int!) {
          books(page: $page) {
            collection {
              ...BookDetails
            }
          }
        }
        ${BOOK_DETAILS_FRAGMENT}
      `
    },
  },
};
</script>
