<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          tile
          color="black"
          class="faded-outline-button ml-sm-3 mt-2 mt-sm-0"
          outlined
          :small="$vuetify.breakpoint.md || $vuetify.breakpoint.sm"
          :x-small="$vuetify.breakpoint.xs"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("bookList.addToList") }}
          <v-icon
            right
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t("common.book") }}
          </v-icon>
        </v-btn>
      </template>
      <v-card
        tile
        class="fill-height filter-dialog"
        color="lightestAccent"
      >
        <v-card-title
          class="filter-header-container has-box-shadow pa-3"
          align="center"
        >
          <h2>{{ $t("bookList.manageLists") }}</h2>
          <v-spacer />
          <v-btn
            icon
            color="textOnPrimary"
            @click="toggleDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <add-to-book-list-form
            :book-id="bookId"
            @onCompleted="onCompleted"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <success-snackbar
      v-model="snackbar"
      :message="snackbarMessage"
    />
  </div>
</template>

<script>
import AddToBookListForm from '@/components/AddToBookListForm';
import SuccessSnackbar from '@/components/SuccessSnackbar';

export default {
  name: "AddToBookListModal",
  components: { 
    AddToBookListForm,
    SuccessSnackbar,
  },
  props: {
    bookId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      snackbarMessage: '',
      snackbar: false,
    }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    onCompleted(event) {
      this.dialog = false;
      this.snackbarMessage = event;
      this.snackbar = true;
    },
  },
}
</script>

<style scoped>
:deep(.v-dialog.v-dialog--active) {
  border-radius: 0px;
}
</style>