var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ApolloMutation',{attrs:{"mutation":(gql) => _vm.placeCreditCardOrderMutation,"variables":{
    input: {
      ..._vm.paymentInfo,
      phoneNumber: _vm.phoneNumber ?? null,
      alternativePhoneNumber: _vm.shippingInfo?.altPhoneNumber,
      placeId: _vm.shippingInfo?.placeId,
      addressDetails: _vm.shippingInfo?.addressDetails,
      schoolId: _vm.schoolInfo?.schoolId,
      studentName: _vm.schoolInfo?.studentName,
      studentClass: _vm.schoolInfo?.studentClass,
      ...(_vm.isAdminOrder && {
        isForPhone: true,
        forFullName: _vm.adminOrderDetails?.forName,
        forEmail: _vm.adminOrderDetails?.forEmail,
        eventId: _vm.adminOrderDetails?.eventId,
      })
    }
  }},on:{"done":_vm.onDone,"error":() => { _vm.loading = false; }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate }){return [_c('v-btn',{attrs:{"id":"submit","tile":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading || _vm.invalid},on:{"click":() => {
        _vm.loading = true;
        mutate();
      }}},[_vm._v(" "+_vm._s(_vm.$t('checkout.form.placeOrder'))+" ")]),(_vm.loading)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('checkout.orderProcessing'))+" ")]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }