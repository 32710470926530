<template>
  <div
    v-if="orderFulfillment"
    class="order-status-container pa-2"
  >
    <h3 class="border-bottom mb-2 d-flex align-center justify-space-between pb-2">
      {{ $t('checkout.orderComplete.orderShippingStatus') }}
      <v-chip
        :color="orderFulfillment?.fulfilledAt ? 'success' : 'secondary'"
        label
      >
        {{ orderStatus }}
      </v-chip>
    </h3>
    <p class="mb-3">
      {{ orderStatusText }}
      <span
        v-if="!orderFulfillment?.fulfilledAt"
      >
        {{ $t('checkout.orderComplete.deliveryWindow') }}
      </span>
    </p>
    <div v-if="order?.shippingFeeSet">
      <div class="my-1 d-flex">
        <h4 class="mr-1">
          {{ $t('checkout.delivery.method') }}:
        </h4>
        <div>{{ $t('checkout.delivery.deliveryMethods.delivery') }}</div>
      </div>
      <div>{{ $t('checkout.delivery.deliveryMethods.deliveryText') }}</div>
    </div>
    <div v-else-if="orderFulfillment?.school">
      <div class="my-1 d-flex">
        <h4 class="mr-1">
          {{ $t('checkout.delivery.method') }}:
        </h4>
        <div>{{ $t('checkout.delivery.deliveryMethods.school') }}</div>
      </div>
      <div>{{ $t('checkout.delivery.deliveryMethods.schoolText') }}</div>
    </div>
    <div v-else>
      <div class="my-1 d-flex">
        <h4 class="mr-1">
          {{ $t('checkout.delivery.method') }}:
        </h4>
        <div>{{ $t('checkout.delivery.deliveryMethods.pickup') }}</div>
      </div>
      <div>{{ $t('checkout.delivery.deliveryMethods.pickupText') }}</div>
    </div>
    <table
      v-if="order?.shippingFeeSet || orderFulfillment?.school"
      class="shipping-details-table"
    >
      <thead>
        <tr v-if="order?.shippingFeeSet">
          <th>{{ $t("profile.phone") }}</th>
          <th>{{ $t("profile.alternativePhoneNumber") }}</th>
          <th>{{ $t("profile.address") }}</th>
          <th>{{ $t("profile.addressDetails") }}</th>
          <th>{{ $t("profile.postalCode") }}</th>
          <th>{{ $t("checkout.delivery.deliveryFee") }}</th>
        </tr>
        <tr v-else>
          <th>{{ $t("profile.phone") }}</th>
          <th>{{ $t("checkout.delivery.school") }}</th>
          <th>{{ $t("checkout.delivery.studentClass") }}</th>
          <th>{{ $t("checkout.delivery.studentName") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="order?.shippingFeeSet">
          <td>{{ orderFulfillment?.phoneNumber ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.altPhoneNumber ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.addressLine1 ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.addressLine2 ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.postalCode ?? $t('common.none') }}</td>
          <td>
            <price
              :book-price="Number(order.shippingFeeSet)"
              :base-currency="order.currency._id"
            />
          </td>
        </tr>
        <tr v-else>
          <td>{{ orderFulfillment?.phoneNumber ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.school?.name ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.studentClass ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.studentName ?? $t('common.none') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Price from "@/components/Price.vue";
import { formatDate } from "@/helpers/generalFunctions";

export default {
  name: "OrderStatus",
  components: {
    Price,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orderFulfillment() {
      return this.order?.orderFulfillment;
    },
    orderStatus() {
      return this.order?.orderFulfillment?.fulfilledAt
        ? this.$t("checkout.orderComplete.deliveryComplete", {
            date: this.formatDate(this.order?.orderFulfillment?.fulfilledAt),
          })
        : this.$t("checkout.orderComplete.deliveryPending");
    },
    orderStatusText() {
      return this.order?.orderFulfillment?.fulfilledAt
        ? this.$t("checkout.orderComplete.deliveryCompleteText")
        : this.$t("checkout.orderComplete.deliveryPendingText");
    },
  },
  methods: {
    formatDate,
  },
};
</script>

<style scoped>
.order-status-container {
  border: 1px solid var(--v-accent-base);
  background-color: var(--v-lightAccent-base);
}

.shipping-details-container {
  font-size: 0.875rem;
}

.border-bottom {
  border-bottom: 1px solid var(--v-accent-base);
}

.shipping-details-table {
  width: 100%;
  border-collapse: collapse;
}

.shipping-details-table th,
.shipping-details-table td {
  text-align: left;
  padding: 0.5rem;
}

.shipping-details-table td {
  background-color: var(--v-accent-base);
}
</style>