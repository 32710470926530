<template>
  <div>
    <v-btn
      tile
      color="primary"
      class="mt-6"
      depressed
      :loading="loading"
      :disabled="loading || (isNewList && invalid)"
      @click="!alreadyInBookList ? addToBookList() : removeBookFromList(alreadyInBookList.id)"
    >
      {{ alreadyInBookList ? $t('bookList.removeFromList') : $t("bookList.addToList") }}
    </v-btn>
  </div>
</template>

<script>
import BookListQueries from "@/graphql/BookListQueries";
import gql from "graphql-tag";

export default {
  name: "ModifyBookListButton",
  props: {
    selectedBookList: {
      type: String,
      default: null
    },
    isNewList: {
      type: Boolean,
      default: false
    },
    newListTitle: {
      type: String,
      default: null
    },
    bookId: {
      type: String,
      required: true
    },
    invalid: {
      type: Boolean,
      default: false
    },
    bookLists: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    alreadyInBookList() {
      const currenList = this.bookLists.find((list) => list.id === this.selectedBookList);

      if (currenList) {
        return currenList?.bookListItems?.find(book => book.productId === this.bookId);
      }
      return false;
    },
    bookListQuery() {
      return gql`
        query getBookList($id: ID!) {
          bookList(id: $id) {
            id
            name
            bookListItems(order: {name: "ASC"}) {
              id
            }
          }
        }
      `;
    },
  },
  methods: {
    async addToBookList() {
      this.loading = true;
      const result = await this.$apollo.mutate({
        mutation: BookListQueries.addItemToBookList,
        variables: {
          input: {
            itemId: this.bookId,
            bookListIri: this.selectedBookList ?? null,
            newListName: this.newListTitle,
          },
        },
        update: (cache, { data: { addItemToBookList: { bookList }}}) => {
          // If new list was created then update the cache else update the existing list
          if (this.isNewList) {
            cache.modify({
              fields: {
                getMyBookLists(existingBookLists) {
                  return [...existingBookLists, bookList];
                },
              },
            });
          }
          else {
            const data = cache.readQuery({ query: this.bookListQuery, variables: { id: bookList.id } });

            // If BookList cache exists then update it
            if (data) {
              cache.modify({
                id: cache.identify(data.bookList),
                fields: {
                  bookListItems(existingItems = []) {
                    return [...existingItems, ...bookList.bookListItems];
                  }
                }
              })
            }
          }
        },
      });

      if (result.data) {
        this.loading = false;
        this.$emit("updateSelectedList", result.data.addItemToBookList.bookList.id);
        this.$emit("onCompleted", this.$t('bookList.addedToList'));
      }
    },
    async removeBookFromList(bookListItemId) {
      this.loading = true;
      const result = await this.$apollo.mutate({
        mutation: BookListQueries.deleteBookListItem,
        variables: {
          id: bookListItemId,
        },
        update: (cache) => {
          const data = cache.readQuery({ query: this.bookListQuery, variables: { id: this.selectedBookList } });

          // If BookList cache exists then update it
          if (data) {
            cache.modify({
              id: cache.identify(data.bookList),
              fields: {
                bookListItems(existingItems, { readField }) {
                  return existingItems.filter((item) => readField('id', item) !== bookListItemId);
                }
              }
            })
          }
        },
      });

      if (result.data) {
        this.loading = false;
        this.$emit("onCompleted", this.$t('bookList.removedFromList'));
      }
    },
  },
}
</script>