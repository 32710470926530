<template>
  <v-container class="pa-0">
    <v-row justify="center">
      <v-col>
        <order-book-display-item
          :order-line="orderLine"
          :order-currency="orderCurrency"
          :show-quantity-input="showQuantityInput"
        />
      </v-col>
      <v-col cols="auto">
        <remove-from-cart-button
          :id="orderLine.id"
          :order-id="orderId"
          @updateLoading="(event) => removeLoading = event"
          @onRemoved="(event) => $emit('onRemoved', event)"
        />
      </v-col>
    </v-row>
    <div
      v-if="removeLoading"
      class="d-flex justify-center align-center loading-overlay"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="30"
      />
    </div>
  </v-container>
</template>
<script>
import RemoveFromCartButton from "./RemoveFromCartButton";
import currencyMixins from "@/mixins/currencyMixins";
import OrderBookDisplayItem from "@/components/OrderBookDisplayItem";

export default {
  name: "CartListItem",
  components: {
    RemoveFromCartButton,
    OrderBookDisplayItem,
  },
  mixins: [currencyMixins],
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    orderCurrency: {
      type: Object,
      required: true,
    },
    showQuantityInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      removeLoading: false,
    };
  },
};
</script>

<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(222, 222, 222, 1);
  opacity: 0.3;
}
</style>