<template>
  <verification-prompt :is-verified="isVerified" />
</template>

<script>
import VerificationPrompt from "@/components/VerificationPrompt";
import gql from "graphql-tag";
import { AUTH_TOKEN } from "@/apollo/vue-apollo";

export default {
  name: "VerificationPending",
  components: {
    VerificationPrompt,
  },
  computed: {
    isVerified() {
      return this.$auth?.user?.email_verified;
    }
  },
  apollo: {
    checkEmailVerifiedWebServiceUser: {
      query: gql`
        query pollForVerification {
          checkEmailVerifiedWebServiceUser {
            id
            emailVerified
          }
        }
      `,
      skip() {
        return this.isVerified;
      },
      pollInterval: 1000,
      fetchPolicy: 'network-only',
      async result({ data }) {
        if (data?.checkEmailVerifiedWebServiceUser?.emailVerified) {
          const newToken = await this.$auth.getTokenSilently({ignoreCache: true});
          const result = await this.$auth.getIdTokenClaims({ignoreCache: true});

          if (result.email_verified) {
            localStorage.setItem(AUTH_TOKEN, newToken);

            try {
              await this.$apollo.provider.defaultClient.clearStore()
            } catch (e) {
              console.error('%cError on cache reset (login)', 'color: orange;', e.message);
            }

            this.$auth.refetchUser();
          }
        }
      },
    },
  },
}
</script>