<template>
  <v-autocomplete
    ref="searchBar"
    v-model="selected"
    :search-input.sync="searchText"
    class="repository-search"
    :items="books && searchTextValid ? books.collection : []"
    prepend-inner-icon="search"
    :loading="loading"
    :allow-overflow="false"
    hide-spin-buttons
    flat
    hide-details
    cache-items
    solo
    dense
    outlined
    tile
    clearable
    :label="$t('menu.searchEkitabu')"
    hide-no-data
    :light="true"
    small-chips
    :item-text="getItemText"
    item-value="id"
    :filter="customFilter"
    @keyup.esc="clear"
    @keydown.enter="event => navigateToResults(event)"
  >
    <template #selection />
  </v-autocomplete>
</template>

<script>
  import gql from "graphql-tag";

  export default {
    data() {
      return {
        searchText: "",
        loading: false,
        selected: null,
      };
    },
    apollo: {
      books: {
          query: gql` query searchProducts($page: Int! $searchText: String) {
            books(page: $page, search: $searchText) {
              collection {
                id
                title
                description
              }
            }
          }`,
          variables() {
            return {
              searchText: this.searchText,
              page: 1,
            }
          },
          throttle: 500,
          skip() {
            if (this.searchTextValid) {
                return false
            }
            return true
          },
        watchLoading(isLoading) {
          this.loading = isLoading
        },
      }
    },
    computed: {
      searchTextValid() {
        return this.searchText != null && this.searchText.length > 2;
      }
    },
    watch: {
      selected(val) {
        this.$refs.searchBar.clearableCallback();
        if (val) {
          this.$router.push({ name: "book", params: { id: val } });
        }
      }
    },
    methods: {
      clear() {
        this.searchText = "";
      },
      customFilter (item, queryText, itemText) {
        if (this.searchText == null || this.searchText == "" || this.searchText.length < 3) return false;

        const textOne = item.title.toLowerCase();
        const textTwo = item.description.toLowerCase();
        const searchText = queryText.toLowerCase();

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
      containsString(text) {
        if(this.searchTextValid) {
          return !!text.toLowerCase().includes(this.searchText.toLowerCase());
        }
        
        return null;
      },
      getItemText(item) {
        return this.containsString(item.title) ? item.title : item.description;
      },
      navigateToResults() {
        if (this.searchText && this.$route?.params?.searchTerm != this.searchText) {
          this.$router.push({ name: "searchResults", params: { searchTerm: this.searchText } });
        }
        this.$refs.searchBar.isFocused = false;
      },
    },
  };
</script>

<style scoped>
  .repository-search.v-text-field {
    border-radius: 0px;
  }
</style>
