var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-container"},[_c('validation-provider',{ref:"validationProvider",attrs:{"rules":_vm.rules,"name":_vm.label,"detect-input":false,"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.label)?_c('label',{staticClass:"input-label",style:(_vm.labelStyle),attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('vue-tel-input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('### ### ###'),expression:"'### ### ###'"}],ref:"phoneInput",staticClass:"input-field px-3",attrs:{"value":_vm.currentValue,"auto-format":false,"dropdown-options":{
        showFlags: true,
        disabled: _vm.dropdownDisabled,
        showDialCodeInSelection: true,
      },"input-options":{
        id: _vm.id,
        placeholder: _vm.placeholder,
        maxlength: 11,
      },"default-country":"KE","only-countries":['KE', 'RW', 'MW']},on:{"input":_vm.inputChanged}},'vue-tel-input',_vm.$attrs,false)),_vm._l((errors),function(error,index){return _c('span',{key:index,staticClass:"error-text"},[_vm._v(" "+_vm._s(error)+" ")])})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }