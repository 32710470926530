<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="order">
          <v-card-title>
            {{ $t('checkout.orderNumber', { number: order._id }) }}
          </v-card-title>
          <v-card-text class="mt-4">
            <v-row>
              <v-col>
                <h3>{{ $t('checkout.orderPending.orderStatus') }}</h3>
                <v-chip
                  :color="chipColor"
                  text-color="white"
                >
                  {{ orderStatus }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row v-if="orderStatus == STATUSES.FAILED">
              <v-col>
                <p>{{ $t('checkout.orderPending.safaricomDeclined') }}:</p>
                <h3>{{ safaricomMpesaRequest.resultDescription }}</h3>
              </v-col>
            </v-row>
            <v-row class="mt-9">
              <v-col>
                <h1>{{ $t('checkout.orderSummary') }}</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-simple-table v-if="order">
                <thead>
                  <tr>
                    <th>{{ $t('checkout.orderPending.product') }}</th>
                    <th>{{ $t('checkout.orderPending.price') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="orderLine in order.orderLines.edges.map((edge) => edge.node)"
                    :key="orderLine.id"
                  >
                    <td>{{ orderLine.name }}</td>
                    <td>
                      <price
                        :book-price="Number(orderLine.preTaxPrice)"
                        :base-currency="order.currency._id"
                      />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr
                    v-if="order?.totalDiscount > 0"
                    class="discount-text"
                  >
                    <td>{{ $t('checkout.discount') }}</td>
                    <td>
                      <price
                        :book-price="Number(order.totalDiscount)"
                        :base-currency="order.currency._id"
                        :show-free-text="false"
                      />
                    </td>
                  </tr>
                  <tr v-if="order?.shippingFee">
                    <td>{{ $t('checkout.delivery.shippingFee') }}</td>
                    <td>
                      <price
                        :book-price="Number(order.shippingFee)"
                        :base-currency="order.currency._id"
                        :show-free-text="false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('checkout.subtotal') }}</td>
                    <td>
                      <price
                        :book-price="Number(order.subtotalPrice)"
                        :base-currency="order.currency._id"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('checkout.vat') }}</td>
                    <td>
                      <price
                        :book-price="Number(order.totalTax)"
                        :base-currency="order.currency._id"
                      />
                    </td>
                  </tr>
                  <tr class="font-weight-bold">
                    <td>{{ $t('checkout.total') }}</td>
                    <td>
                      <price
                        :book-price="Number(order.totalPrice)"
                        :base-currency="order.currency._id"
                      />
                    </td>
                  </tr>
                </tfoot>
              </v-simple-table>
            </v-row>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-else 
          type="table-heading, list-item-two-line, image, table-tfoot"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookQueries from "@/graphql/BookQueries";
import gql from "graphql-tag";
import Price from "@/components/Price";

export default {
  name: "OrderPending",
  components: {
    Price,
  },
  data() {
    return {
      order: null,
    };
  },
  apollo: {
    // TODO: Get the mercure integration working in order to dynamically get updates on the order status
    //  https://github.com/ekitabu-LLC/shop-web/issues/53
    order: {
      query: gql`
        query getOrderStatus($id: ID!, $safaricomMpesaRequestId: String) {
          order(id: $id) {
            id
            _id
            confirmed
            totalTax
            totalPrice
            subtotalPrice
            totalDiscount
            shippingFee
            taxRate {
              id
              rate
            }
            currency {
              id
              _id
            }
            orderLines(first: 1000) {
              edges {
                node {
                  id
                  productId
                  name
                  price
                  preTaxPrice
                  discount
                }
              }
            }
            safaricomMpesaRequests(id: $safaricomMpesaRequestId) {
              id
              resultCode
              resultDescription
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          safaricomMpesaRequestId: this.$route.params.safaricomMpesaRequestId,
        };
      },
      async result({ data }) {
        if (data.order.confirmed) {
          await this.$apollo.query({
            query: BookQueries.getUserBookshelf,
            fetchPolicy: "network-only",
          });
          this.$store.commit("setCurrentCart", null);
          this.$router.push({
            name: "confirmed",
            params: {
              id: this.$route.params.id,
            },
          });
        }
      },
      pollInterval: 1500,
    },
  },
  computed: {
    orderStatus() {
      if (this.safaricomMpesaRequest && this.safaricomMpesaRequest.resultCode) {
        const resultCodeInteger = parseInt(
          this.safaricomMpesaRequest.resultCode
        );
        if (resultCodeInteger === 0) {
          return this.STATUSES.CONFIRMED;
        } else if (resultCodeInteger > 0) {
          return this.STATUSES.FAILED;
        }
      }

      return this.STATUSES.PENDING;
    },
    safaricomMpesaRequest() {
      if (this.order && this.order.safaricomMpesaRequests.length == 1) {
        return this.order.safaricomMpesaRequests[0];
      } else if (this.order && this.order.safaricomMpesaRequests.length > 1) {
        throw new Error(
          "We weren't able to filter out to one safaricomMpesaRequest"
        );
      }
      return null;
    },
    STATUSES() {
      return {     
        PENDING: this.$t('checkout.orderPending.statuses.pending') ,
        CONFIRMED:  this.$t('checkout.orderPending.statuses.confirmed') ,
        FAILED: this.$t('checkout.orderPending.statuses.failed'),
      }
    },
    chipColor() {
      if (this.orderStatus == this.STATUSES.PENDING) {
        return "warning";
      } else if (this.orderStatus == this.STATUSES.CONFIRMED) {
        return "success";
      } else if (this.orderStatus == this.STATUSES.FAILED) {
        return "black";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.discount-text {
  color: var(--v-success-base);
}
</style>