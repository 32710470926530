<template>
  <ApolloMutation
    :mutation="(gql) => applyCouponMutation"
    :variables="{
      input: {
        id: orderId,
        couponCode: couponCode != '' ? couponCode : null,
      },
    }"
    :refetch-queries="() => [{
      query: getOrderQuery,
      variables: { 
        orderId,
      },
    }]"
    @done="onDone"
  >
    <template #default="{ mutate, loading}">
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <div class="d-flex flex-row align-center">
          <ekitabu-text-input
            id="coupon-code-input"
            v-model="couponCode"
            class="coupon-input"
            rules="min:3|max:30"
            :label="$t('checkout.couponCode')"
            :placeholder="$t('checkout.couponCode')"
          />
          <v-btn
            tile
            depressed
            :loading="loading"
            :disabled="loading || invalid || !couponCode || !isNewCode"
            color="primary"
            class="ml-3"
            @click="mutate"
          >
            {{ $t("common.apply") }}
          </v-btn>
        </div>
      </validation-observer>
    </template>
  </ApolloMutation>
</template>

<script>
import EkitabuTextInput from "@/components/EkitabuTextInput";
import CouponMutations from "@/graphql/CouponMutations";
import OrderQueries from "@/graphql/OrderQueries";
import { max, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import i18n from "@/i18n";

setInteractionMode("eager");

extend("max", {
  ...max,
  message: i18n.t("errors.maxLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("min", {
  ...min,
  message: i18n.t("errors.minLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

export default {
  name: "ApplyCoupon",
  components: {
    EkitabuTextInput,
    ValidationObserver,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    existingCouponCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      couponCode: this.existingCouponCode,
    };
  },
  computed: {
    applyCouponMutation() {
      return CouponMutations.applyCoupon;
    },
    getOrderQuery() {
      return OrderQueries.getOrder;
    },
    isNewCode() {
      if (this.existingCouponCode === null) return true;
      return this.existingCouponCode !== this.couponCode;
    },
  },
  watch: {
    existingCouponCode(val) {
      this.couponCode = val;
    },
  },
  methods: {
    onDone() {
      this.$emit("snackbarMessage", this.$t('checkout.couponApplied'));
    },
  },
}
</script>

<style>
.coupon-input {
  min-width: 300px;
}

.coupon-input .input-label {
  display: none;
}
</style>