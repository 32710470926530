<template>
  <v-container class="edit-profile-container pa-3">
    <change-profile-info-form />
    <change-password-form />
  </v-container>
</template>

<script>
import ChangeProfileInfoForm from "@/views/user/ChangeProfileInfoForm.vue";
import ChangePasswordForm from "@/views/user/ChangePasswordForm.vue";

export default {
  components: {
    ChangeProfileInfoForm,
    ChangePasswordForm,
  },
};
</script>

<style scoped>
.edit-profile-container {
  background-color: var(--v-lightestAccent-base);
}
</style>
