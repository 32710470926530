var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ApolloMutation',{ref:"updateProfile",attrs:{"mutation":(gql) => _vm.updateProfileMutation,"variables":{
    input: {
      phoneNumber: _vm.formattedPhone,
      alternativePhoneNumber: _vm.shippingData.altPhoneNumber,
      givenName: _vm.givenName,
      familyName: _vm.familyName,
      placeId: _vm.shippingData.placeId,
      addressDetails: _vm.shippingData.addressDetails,
    },
  },"update":_vm.updateCache},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [(!_vm.$apollo.queries.getWebServiceUser.loading)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center edit-header-container pb-3 mb-6"},[_c('h1',{staticClass:"edit-header"},[_vm._v(" "+_vm._s(_vm.$t("profile.editUserInfo"))+" ")]),_c('router-link',{staticClass:"back-link",attrs:{"to":{ name: 'userProfile' }}},[_vm._v(" "+_vm._s(_vm.$t("common.back"))+" ")])],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-form',{ref:"userInfoForm"},[_c('ekitabu-text-input',{attrs:{"id":"given-name-input","rules":"max:50","label":_vm.$t('profile.givenName'),"placeholder":_vm.$t('profile.givenName')},model:{value:(_vm.givenName),callback:function ($$v) {_vm.givenName=$$v},expression:"givenName"}}),_c('ekitabu-text-input',{staticClass:"mt-2",attrs:{"id":"family-name-input","rules":"max:50","label":_vm.$t('profile.familyName'),"placeholder":_vm.$t('profile.familyName')},model:{value:(_vm.familyName),callback:function ($$v) {_vm.familyName=$$v},expression:"familyName"}}),_c('ekitabu-masked-phone-input',{staticClass:"mt-2",attrs:{"id":'phone-input',"value":_vm.accountPhone,"label":_vm.$t('checkout.form.phoneNumber'),"rules":"max:9|min:9","placeholder":"### ### ###","type":"tel"},on:{"formattedPhoneChanged":_vm.setFormattedPhone}}),_c('address-fields',{on:{"updateShippingDetails":_vm.updateAddressDetails,"updateIsChanged":_vm.updateIsChanged}}),_c('v-btn',{staticClass:"mt-4",attrs:{"tile":"","block":_vm.$vuetify.breakpoint.xs,"small":_vm.$vuetify.breakpoint.xs,"elevation":"0","loading":loading,"disabled":invalid || !_vm.valuesChanged || loading,"color":"primary"},on:{"click":mutate}},[_vm._v(" "+_vm._s(_vm.$t("profile.save"))+" ")])],1)]}}],null,true)})],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }