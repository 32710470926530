<template>
  <div class="data-table-header-container mx-n6 px-6 px-md-6 mb-6">
    <v-row no-gutters>
      <v-col class="col-auto">
        <h1 class="customer-header-title mb-5">
          {{ title ?? event?.name }}
        </h1>
        <div
          v-if="!event"
          class="d-flex"
          style="gap: 100px"
        >
          <v-skeleton-loader
            type="text@4"
            width="200px"
            height="120px"
          />
          <v-skeleton-loader
            type="text@4"
            width="200px"
            height="120px"
          />
        </div>
        <div
          v-else
          class="d-flex"
          style="gap: 100px"
        >
          <div>
            <div class="info-item">
              <span>{{ $t("event.startDate") }}: </span>
              {{ formatDate(event?.startDate) }}
            </div>
            <div class="info-item">
              <span>{{ $t("event.totalOrders") }}: </span>
              {{ event.orders.totalCount }}
            </div>
            <div class="info-item">
              <span>{{ $t("event.numberOfUsers") }}: </span>
              {{ event.uniqueCustomers }}
            </div>
            <div class="info-item">
              <span>{{ $t("event.totalPrice") }}: </span>
              {{ "KES "+event.totalCost }}
            </div>
          </div>
          <div>
            <div class="info-item">
              <span>{{ $t("event.endDate") }}: </span>
              {{ formatDate(event.endDate) }}
            </div>
            <div class="info-item">
              <span>{{ $t("event.quantity") }}: </span>
              {{ event.totalQuantity }}
            </div>
            <div class="info-item">
              <span>{{ $t("event.bestSeller") }}: </span>
              {{ event.mostPurchasedBook?.length > 1 ? event.mostPurchasedBook[0]+` (${event.mostPurchasedBook[1]})` : $t('common.none') }}
            </div>
            <div class="info-item">
              <span>{{ $t("event.region") }}: </span>
              {{ event.taxRate._id }}
            </div>
          </div>
        </div>
      </v-col>
      <v-spacer />
      <v-col class="col-12 col-sm-auto mt-auto">
        <v-btn
          class="lightAccent mt-3"
          width="270"
          tile
          depressed
          block
          :to="toPrevious"
        >
          {{ $t("common.backToPrevious") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { formatDate } from "@/helpers/generalFunctions";

export default {
  name: "OrderDetails",
  props: {
    eventId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    totalOrders: {
      type: Number,
      default: 0,
    },
    toPrevious: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggle: this.toggleValue,
    };
  },
  apollo: {
    event: {
      query: gql`
        query getEvent($id: ID!) {
          event(id: $id) {
            id
            name
            startDate
            endDate
            createdAt
            updatedAt
            taxRate {
              id
              _id
              rate
            }
            orders {
              totalCount
            }
            totalCost
            totalQuantity
            mostPurchasedBook
            uniqueCustomers
          }
        }
      `,
      variables() {
        return {
          id: this.eventId,
        };
      },
    },
  },
  methods: {
    formatDate,
  },
};
</script>