import gql from "graphql-tag";

export default {
  bookDetailsFragment: gql`
    fragment BookDetails on Book {
      id
      title
      authors
      thumbnailUrl
      price
      format
      currency {
        id
        _id
      }   
    }
  `,
};